import { LegacyDevice } from '../../../models/device-legacy.dto';
import { Dispatch } from 'react';
import { AppAction } from '../../app.action';

import { getLegacyDevices, createLegacyDeviceFromArrayInfo } from '../../../utils/legacy-device/legacy-device.helpers';

export const fetchLegacyDevices = (user: string) => {
  return async (dispatch: Dispatch<AppAction>): Promise<void> => {
    // Make the state of the legacy device list loading
    dispatch({
      type: "SetIsLoadingLegacyDevices",
      payload: true
    });

    // get the devices for the current user
    getLegacyDevices(user)
      .then(response => {
        // console.log(response)
        if (Object.prototype.hasOwnProperty.call(response.body, 'message')) {
          // console.log(response.body.message);
          const legacyDevicesArray = response.body.message;
          let tempLegDevArray: LegacyDevice[] = [];

          legacyDevicesArray.forEach((deviceArr: any) => {
            let tempDevice: LegacyDevice = createLegacyDeviceFromArrayInfo(deviceArr)
            tempLegDevArray.push(tempDevice);
          });

          // Save the legacy device list state on the global store
          dispatch({
            type: "SetLegacyDevices",
            payload: tempLegDevArray
          });
        } else {
          dispatch({
            type: "SetIsLoadingLegacyDevices",
            payload: false
          })
        }
      })
      .catch(err => {
        console.log('Something went wrong fetching the legacy device: ', err)
        dispatch({
          type: "SetIsLoadingLegacyDevices",
          payload: false
        })
      })
      .finally(() => {
        dispatch({
          type: "SetIsLoadingLegacyDevices",
          payload: false
        })
      })
  }
}