import React, {
  PropsWithChildren,
  CSSProperties,
  useEffect,
  useState,
} from "react"
import { ApolloProvider } from "react-apollo"
import { useSelector, useDispatch } from "react-redux"
import { AppState } from "./app.state"
import { UserState } from "./user"
import { useIdleTimer } from 'react-idle-timer'
import { navigate } from '@reach/router';
import { setUser } from "../state/user/actions/set-user.action";

import { getApolloClient } from "./apollo.client"
import { IntlProvider } from "./intl.context"
import { MainLayout } from "../components/layout/main.layout"

import { Layout, Grid, Modal, Typography } from "antd"
import { ClockCircleOutlined } from '@ant-design/icons';
import { createLog } from "../utils/extras/log.helpers"
import landingBanner from "../../static/images/siteIcons/landing/landing-banner.png"
import landingBannerMobile from "../../static/images/siteIcons/landing/landing-banner-mobile.png"

const apolloClient = getApolloClient()

const { useBreakpoint } = Grid

export const ApiClientContext = React.createContext(apolloClient)

// createLog(`PATH NAME >>> ${window.location}`)

// Background Image for Landing Page
const landingBackground: CSSProperties = {
  backgroundImage: `url(${landingBanner})`,
  backgroundRepeat: "no-repeat",
  backgroundPositionY: "1em",
  minHeight: "88vh",
  //backgroundSize: 'contain',
}

// Background image for landing page
const landingBackgroundMobile: CSSProperties = {
  backgroundImage: `url(${landingBannerMobile})`,
  backgroundRepeat: "no-repeat",
  backgroundPositionY: "1em",
  minHeight: "88vh",
  backgroundSize: 'contain',
}

export const AppProvider = (props: PropsWithChildren<any>) => {
  const { user } = useSelector<AppState, UserState>((state) => state.user)
  const dispatch = useDispatch()

  const [path, setPath] = useState("");

  const screen = useBreakpoint()

  useEffect(() => {
    // console.log("PATH >>> ", window.location.pathname)
    setPath(location.pathname)
  }, [])
  
  
  // Handle event after idle time expires
  const handleOnIdle = () => {
    // Number of seconds to show modal before logging user out
    let secondsToGo = 60;
    
    // Handle Logout
    const handleLogout = () => {
      const clearUser: User = {Username: '', AccessToken: '', RefreshToken: '', Profile: undefined};
      localStorage.clear();
      dispatch(setUser(clearUser));
      navigate('/landing', {replace: true});
    }
    
    // Handle stay logged in button press
    const handleStayLoggedIn = () => {
      clearInterval(timer)
      clearTimeout(timeout)
      modal.destroy()
    }
    
    const modal = Modal.confirm({
      title: `Your session is expiring and you’ll be logged out in ${secondsToGo} seconds.`,
      icon: <ClockCircleOutlined />,
      okText: 'Log Out Now',
      cancelText: 'Stay Logged In',
      onOk: handleLogout,
      onCancel: handleStayLoggedIn
    })
    const timer = setInterval(() => {
      secondsToGo -= 1
      modal.update({
        title: `Your session is expiring and you’ll be logged out in ${secondsToGo} seconds.`,
      })
    }, 1000)
    const timeout = setTimeout(() => {
      clearInterval(timer)
      clearTimeout(timeout)
      modal.destroy()
      handleLogout()
      
    }, secondsToGo * 1000)
  }

  const handleOnAction = () => {
    //console.log('user is active', event)
    //console.log('Last active', getLastActiveTime())
    localStorage.setItem('lastActive',getLastActiveTime().toString())
  }
  
  const { pause, resume, getLastActiveTime } = useIdleTimer({
    // Timeout is set to 14 minutes, then modal counts down the 15th minute
    timeout: 1000 * 60 * 14,
    onIdle: handleOnIdle,
    onAction: handleOnAction,
    debounce: 500
  })
  
  !user.Username ? pause() : resume()

  return (
    <IntlProvider>
      <ApolloProvider client={apolloClient}>
        <MainLayout>
          <Layout.Content
            // TODO: find a way to remove the background image on forgot-password and verified pages.
            // This approach is not working properly.
            style={!user.Username && (path === "/landing" || path === "/landing/") ? 
            screen.lg ? landingBackground : landingBackgroundMobile 
            : { minHeight: "88vh" }}
          >
            {props.children}
          </Layout.Content>
        </MainLayout>
      </ApolloProvider>
      </IntlProvider>
  )
}
