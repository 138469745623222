import React, { CSSProperties } from 'react';
import { useSelector } from 'react-redux';
import { AppState, UserState } from '../../state';

import { Layout, Grid } from 'antd';

import { HeaderLayout } from './header.layout';
import { FooterLayout } from './footer.layout';

// import './main-layout.css';
// import './layout.scss';
import '../shared/global.styles.scss';

interface MainLayoutProps {
    className?: string;
}

const { useBreakpoint } = Grid

export const MainLayout: React.FC<MainLayoutProps> = ({className, children}) => {

    const { user } = useSelector<AppState, UserState>(state => state.user);
    const screen = useBreakpoint()

    const layoutStyle: CSSProperties = screen.lg ? { padding: '2rem' } : { padding: '0' } 

    return (
        <Layout>
            {/* HEADER */}
            <HeaderLayout />
            {/* REST OF COMPONENTS */}
            {children}
            FOOTER
            {!user.Username ? <FooterLayout /> : null}
        </Layout>
    );
};
