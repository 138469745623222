import React from 'react'
import { Form, Select } from 'antd'

const { Option } = Select

interface CountryCodeFlagProps {
  onChange: (value: string) => void;
  inputId?: string;
  disabled?: boolean;
}

export const CountryCodeFlag: React.FC<CountryCodeFlagProps> = props => {
  const { onChange, inputId, disabled } = props
  const flagStyle = {with: '26', marginTop: '-0.2rem'}
  // https://www.countryflags.com/en/mexico-flag-image.html
  const imgOptionConf = {
      us: {
        src: "/images/siteIcons/general-icons/country-flags/us-flag-icon-32.png",
        alt: "us-img-flag"
      },
      ca: {
        src: "/images/siteIcons/general-icons/country-flags/ca-flag-icon-32.png" ,
        alt: "ca-img-flag"
      },
      mx: {
        src: "/images/siteIcons/general-icons/country-flags/mx-flag-icon-32.png",
        alt: "mx-img-flag"
      },
  }

  return (
    <Form.Item name="countryFlag" noStyle>
      <Select
        id={inputId}
        disabled={disabled}
        onChange={onChange}
      >
        <Option value="us">
          <img 
            style={flagStyle}
            src={imgOptionConf.us.src}
            alt={imgOptionConf.us.alt}
          />
        </Option>
        <Option value="ca">
          <img 
            style={flagStyle}
            src={imgOptionConf.ca.src}
            alt={imgOptionConf.ca.alt}
          />
        </Option>
        <Option value="mx">
          <img 
            style={flagStyle}
            src={imgOptionConf.mx.src}
            alt={imgOptionConf.mx.alt}
          />
        </Option>
        {/* <Option value="us">
          <span role="img" aria-label="US" style={{ fontSize: '18px' }}>
            🇺🇸
          </span>
        </Option>
        <Option value="ca">
          <span role="img" aria-label="CA" style={{ fontSize: '18px' }}>
            🇨🇦
          </span>
        </Option>
        <Option value="mx">
          <span role="img" aria-label="MX" style={{ fontSize: '18px' }}>
            🇲🇽
          </span>
        </Option> */}
      </Select>
    </Form.Item>
  )
}
