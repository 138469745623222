import { addLegacyDevice, AddLegacyDeviceAction } from './add-legacy-device.action';
import { setLegacyDevices, SetLegacyDevicesAction } from './set-legacy-devices.action';
import { setIsLoadingLegacyDevices, SetIsLoadingLegacyDevicesAction } from './set-is-loading-legacy-devices.action';
import { fetchLegacyDevices } from './fetch-legacy-devices.action';

// Exporting the types
export type LegacyDeviceAction = AddLegacyDeviceAction | SetLegacyDevicesAction | SetIsLoadingLegacyDevicesAction;

// Exporting the actions as json object
export const legacyDeviceActions = {
  addLegacyDevice,
  setLegacyDevices,
  setIsLoadingLegacyDevices,
  fetchLegacyDevices
}