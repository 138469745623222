import { notification } from 'antd'
// import { NotificationInstance } from "antd/lib/notification"

export const showNotification = (
  type: 'error' | 'success',
  message: string,
  description: string,
  duration: number = 10,
) => {
  return type === 'error'
    ? notification.error({
        top: 50,
        message: message,
        description: description,
        duration: duration,
      })
    : notification.success({
        top: 50,
        message: message,
        description: description,
        duration: duration,
      })
}
