import React, { useState } from 'react'
import { Button } from 'antd'
import { LoginFormModal } from '../modals/account/login-form-modal'
import { RegisterFormModal, RegistrationFormModal } from '../modals/account/registration-form-modal'

export const LoginSignupButtons: React.FC = () => {
  // LOCAL STATE
  const [registerDrawer, setRegisterDrawer] = useState(false)
  const [loginDrawer, setLoginDrawer] = useState(false)
  
  const openLoginDrawer = () => {
    setLoginDrawer(true)
  }

  const openSignupDrawer = () => {
    setRegisterDrawer(true)
  }

  const closeLoginDrawer = () => {
    setLoginDrawer(false)
  }

  const closeRegisterDrawer = () => {
    setRegisterDrawer(false)
  }
  
  return (
    <div>
      <Button
        style={{marginRight: '5px'}}
        size="large"
        onClick={openLoginDrawer}
      >
        Login
      </Button>
      <Button
        size='large'
        onClick={openSignupDrawer}
      >
        Signup
      </Button>
      <RegisterFormModal visible={registerDrawer} closeDrawer={closeRegisterDrawer} />
      <LoginFormModal visible={loginDrawer} closeDrawer={closeLoginDrawer} />
    </div>
  )
}