
/**
 * Intended to format AWS backend error to UI/UX friendly error messages
 * @param errorMessage string The error message coming from AWS
 */
export const getIntlErrorID = (errorMessage: string) => {
  switch (errorMessage) {
    case 'Not Authorized':
      return 'app.aws.errors.not-authorized';
    case 'Incorrect Password':
      return 'app.aws.errors.incorrect-password';
    case 'User Not Found':
      return 'app.aws.errors.user-not-found';
    case 'Too Many Request':
      return 'app.aws.errors.too-many-reauests';
    case 'Limit Exceeded':
      return 'app.aws.errors.limit-exceeded';
    case 'User Not Confirmed':
      return 'app.aws.errors.user-not-confirmed';
    case 'Username Already Exist':
      return 'app.aws.errors.user-already-exist';
    case 'Invalid Auth Key':
      return 'app.aws.errors.invalid-auth-key';
    case 'Auth Key Undefined':
      return 'app.aws.errors.auth-key-undefined';
    default:
      return 'app.general.error';
  }
}