import { SetDevicesAction, setDevices } from "./set-devices.action";
import { SetIsLoadingDevicesAction, setIsLoadingDevices } from "./set-is-loading-devices.action";
import { SetDeviceLiveDataAction } from "./set-device-live-data.action";
import { refreshDeviceList } from "./refresh-device-list.action";

export type DevicesAction = SetDevicesAction | SetIsLoadingDevicesAction | SetDeviceLiveDataAction;

export const devicesActions = {
    setDevices,
    setIsLoadingDevices,
    refreshDeviceList
};
