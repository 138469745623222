import Axios from 'axios';
import jwtDecode from 'jwt-decode';

import { User, ProfileData, CognitoUserAttributes } from '../../models';
import { createLog } from '../extras/log.helpers';

const axiosHTTP = Axios.create({
  // baseURL: env.API_V2
  baseURL: process.env.API_V2,
  headers: {
    "auth-api-key": process.env.CUSTOM_AUTH_KEY
  }
});

/**
 * Cognito Sign In
 * @param username string
 * @param password string
 */
export const login = async (username: string, password: string) => {
  const axiosRequestConfig = {
    action: 'signIn',
    username: username,
    password: password,
  };

  try {
    const result = await axiosHTTP.post(process.env.ACCOUNT_HANDLER_PATH!, axiosRequestConfig);
    if (result.data.status) {
      let userData: User = { Username: '', AccessToken: '', RefreshToken: '', Profile: undefined};
      const cognitoUser = JSON.parse(result.data.data);
      // createLog(JSON.stringify(cognitoUser, null, 4));
      // Clear local storage before saving new information
      localStorage.clear();
      // localStorage.setItem('token', cognitoUser.AuthenticationResult.AccessToken);
      // localStorage.setItem('refreshToken', cognitoUser.AuthenticationResult.RefreshToken);

      const userProfile: ProfileData = jwtDecode(cognitoUser.AuthenticationResult.IdToken);

      userData = {
        Username: userProfile["cognito:username"],
        AccessToken: cognitoUser.AuthenticationResult.AccessToken,
        RefreshToken: cognitoUser.AuthenticationResult.RefreshToken,
        Profile: userProfile
      };
      // Set user data to local storage
      localStorage.setItem('user_data', JSON.stringify(userData));
      // createLog(JSON.stringify(userData, null, 4));
      return userData;
    } else {
      // createLog(JSON.stringify(result, null, 4));
      return result.data.error.message;
    }
  } catch (err) {
    createLog(`ERROR on sign in: ${err}`);
    return err
  }
}

/**
 * Intended to refresh user token hence user information after chenged
 * @param refreshToken string current user refresh token
 */
export const refreshUserInformation = async (refreshToken: string) => {
  const axiosRequestConfig = {
    action: 'refreshUserInformation',
    refreshToken: refreshToken
  };

  try {
    const result = await axiosHTTP.post(process.env.ACCOUNT_HANDLER_PATH!, axiosRequestConfig);
    if (result.data.status) {
      let userData: User = { Username: '', AccessToken: '', RefreshToken: '', Profile: undefined};
      const cognitoUser = JSON.parse(result.data.data);
      // createLog(JSON.stringify(cognitoUser, null, 4));
      // Clear local storage to save refreshed information
      localStorage.clear();
      // localStorage.setItem('token', cognitoUser.AuthenticationResult.AccessToken);
      // localStorage.setItem('refreshToken', refreshToken);

      const userProfile: ProfileData = jwtDecode(cognitoUser.AuthenticationResult.IdToken);

      userData = {
        Username: userProfile["cognito:username"],
        AccessToken: cognitoUser.AuthenticationResult.AccessToken,
        RefreshToken: refreshToken,
        Profile: userProfile
      };
      // Set user data to local storage
      localStorage.setItem('user_data', JSON.stringify(userData));
      // createLog(JSON.stringify(userData, null, 4));
      return userData;
    } else {
      // createLog(JSON.stringify(result, null, 4));
      return result.data.error.message;
    }
  } catch (err) {
    // createLog(err);
    return err
  }
}

/**
 * Cognito Sign Up
 * @param firstName string The user's first name (Cognito given name attribute)
 * @param lastName string The user's last name (Cognito family name attribute)
 * @param username string The user's username
 * @param email string The user's email address
 * @param phoneNumber string The user's phone number
 * @param password string The user's password
 */
export const register = async (
  firstName: string,
  lastName: string,
  username: string,
  email: string,
  phoneNumber: string,
  password: string
) => {
  const axiosRequestConfig = {
    action: 'register',
    firstName: firstName,
    lastName: lastName,
    username: username,
    email: email,
    phoneNumber: phoneNumber,
    password: password,
  };

  try {
    const result = await axiosHTTP.post(process.env.ACCOUNT_HANDLER_PATH!, axiosRequestConfig);
    if (result.data.status) {
      // createLog(JSON.stringify(result, null, 4));
      const registerData = JSON.parse(result.data.data)
      return registerData;
    } else {
      // createLog(JSON.stringify(result, null, 4));
      return result.data.error.message;
    }
  } catch (err) {
    createLog(err);
    return err;
  }
}

/**
 * Intended to confirm an account after successfully registered
 * @param code number the verification code sent by AWS
 * @param username the account username
 */
export const confirmSignUp = async (username: string, code: string) => {
  const axiosRequestConfig = {
    action: 'confirmSignUp',
    username: username,
    code: code,
  };

  try {
    const result = await axiosHTTP.post(process.env.ACCOUNT_HANDLER_PATH!, axiosRequestConfig);
    if (result.data.status) {
      const confirmSignUpData = JSON.parse(result.data.data);
      return confirmSignUpData;
    } else {
      // createLog(JSON.stringify(result, null, 4));
      return result.data.error.message;
    }
  }
  catch (err) {
    // createLog(err);
    return err
  }
}

/**
 * Resend a conformation code to indicated username
 * @param username string The user's username
 */
export const resendConfirmationCode = async (username: string) => {
  const axiosRequestConfig = {
    action: 'resendConfirmationCode',
    username: username,
  };

  try {
    const result = await axiosHTTP.post(process.env.ACCOUNT_HANDLER_PATH!, axiosRequestConfig);
    if (result.data.status) {
      // createLog(JSON.stringify(result, null, 4));
      const resendCodeData = JSON.parse(result.data.data);
      return resendCodeData;
    } else {
      // createLog(JSON.stringify(result, null, 4));
      return result.data.error.message;
    }
  }
  catch (err) {
    createLog(err);
    return err
  }
}

/**
 * Allows users to reset their password
 * @param username string Username or user alias
 */
export const forgotPassword = async (username: string) => {
  const axiosRequestConfig = {
    action: 'forgotPassword',
    username: username,
  };

  try {
    const result = await axiosHTTP.post(process.env.ACCOUNT_HANDLER_PATH!, axiosRequestConfig);
    if (result.data.status) {
      // createLog(JSON.stringify(result, null, 4));
      const forgotPasswordData = JSON.parse(result.data.data);
      return forgotPasswordData;
    } else {
      // createLog(JSON.stringify(result, null, 4));
      return result.data.error.message;
    }
  }
  catch (err) {
    createLog(err);
    return err
  }
}

/**
 * Cognito Confirm Forgot Password
 * @param username string The user's username
 * @param code string The code sent by AWS during forgot password flow
 * @param newPassword string The new password
 */
export const confirmForgotPassword = async (username: string, code: string, newPassword: string) => {
  const axiosRequestConfig = {
    action: 'confirmForgotPassword',
    username: username,
    code: code,
    newPassword: newPassword
  };

  try {
    const result = await axiosHTTP.post(process.env.ACCOUNT_HANDLER_PATH!, axiosRequestConfig);
    if (result.data.status) {
      const confirmForgotPasswordData = JSON.parse(result.data.data);
      return confirmForgotPasswordData;
    } else {
      // createLog(JSON.stringify(result, null, 4));
      return result.data.error.message;
    }
  }
  catch (err) {
    // createLog(err);
    return err
  }
}

/**
 * Update a specific Cognito user attribute
 * @param attributes CognitoUserAttributes[] Array of Cognito user attributes to be updated
 * @param accessToken string The user access token
 */
export const updateUserAttributes = async (attributes: CognitoUserAttributes[], accessToken: string) => {
  const axiosRequestConfig = {
    action: 'updateUserAttributes',
    attributes: attributes,
    accessToken: accessToken
  };

  try {
    const result = await axiosHTTP.post(process.env.ACCOUNT_HANDLER_PATH!, axiosRequestConfig);
    if (result.data.status) {
      // createLog(JSON.stringify(result, null, 4));
      const updateUserAttributesData = JSON.parse(result.data.data);
      return updateUserAttributesData;
    } else {
      // createLog(JSON.stringify(result, null, 4));
      return result.data.error.message;
    }
  }
  catch (err) {
    // createLog(err);
    return err
  }
}

/**
 * Request a verification code for a specific user attribute
 * @param attribute string The user attribute to be verified (phone number or email)
 * @param accessToken string The user access token
 */
export const getUserAttributeVerificationCode = async (attribute: string, accessToken: string) => {
  const axiosRequestConfig = {
    action: 'getUserAttributeVerificationCode',
    attribute: attribute,
    accessToken: accessToken
  };

  try {
    const result = await axiosHTTP.post(process.env.ACCOUNT_HANDLER_PATH!, axiosRequestConfig);
    if (result.data.status) {
      const getUserAttributeVerificationCodeData = JSON.parse(result.data.data);
      return getUserAttributeVerificationCodeData;
    } else {
      // createLog(JSON.stringify(result, null, 4));
      return result.data.error.message;
    }
  }
  catch (err) {
    // createLog(err);
    return err
  }
}

/**
 * Vrifies a specific user attribute
 * @param accessToken string The user access token
 * @param attribute string The user attribute to be verified
 * @param code string The verify confirmation code sent by Cognito to the inteded attribute
 */
export const verifyUserAttribute = async (accessToken: string, attribute: string, code: string) => {
  const axiosRequestConfig = {
    action: 'verifyUserAttribute',
    accessToken: accessToken,
    attribute: attribute,
    code: code
  };

  try {
    const result = await axiosHTTP.post(process.env.ACCOUNT_HANDLER_PATH!, axiosRequestConfig);
    if (result.data.status) {
      const verifyUserAttributeData = JSON.parse(result.data.data);
      return verifyUserAttributeData;
    } else {
      // createLog(JSON.stringify(result, null, 4));
      return result.data.error.message;
    }
  }
  catch (err) {
    // createLog(err);
    return err
  }
}

/**
 * Intended to change the current logged user password
 * @param accessToken string The logged user access token
 * @param prevPassword string The current user password
 * @param newPassword string The proposed password
 */
export const changeUserPassword = async (accessToken: string, prevPassword: string, newPassword: string) => {
  const axiosRequestConfig = {
    action: 'changeUserPassword',
    previousPassword: prevPassword,
    proposedPassword: newPassword,
    accessToken: accessToken
  };

  try {
    const result = await axiosHTTP.post(process.env.ACCOUNT_HANDLER_PATH!, axiosRequestConfig);
    if (result.data.status) {
      // createLog(JSON.stringify(result, null, 4));
      const updateUserAttributesData = JSON.parse(result.data.data);
      return updateUserAttributesData;
    } else {
      // createLog(JSON.stringify(result, null, 4));
      return result.data.error.message;
    }
  }
  catch (err) {
    // createLog(err);
    return err
  }
}

/**
 * Checks to see if the session is still valid based on session expiry information found
 * in Access and Id Tokens and the current time
 * @param {string} token The access token to be checked
 * @returns {boolean} if the session is still valid
 */
export const isValidToken = (token: string): boolean => {
  const now = Math.floor(new Date().getTime() / 1000);
  try {
      if (token) {
        const tokenExp = getTokenDecoded(token).exp;
        return now < tokenExp; // Means that token still valid
      }
      return false;
  } catch (e) {
      return false;
  }
}

/**
 * Returns the information from an access token
 * @param {string} token The access token to be decoded
 */
const getTokenDecoded = (token: string): any => {
  // createLog('TOKEN DECODED: ', JSON.stringify(jwtDecode(token), null, 2));
  return jwtDecode(token);
}