import { Device } from '../../../models';
import { Action } from 'redux';

export interface SetDevicesAction extends Action {
    type: 'SetDevices';
    payload: Device[];
}

export const setDevices: (items: Device[]) => SetDevicesAction = items => ({
    type: 'SetDevices',
    payload: items
});
