import gql from 'graphql-tag';

import { Device } from '../models';

export const getDefaultLocationQuery = gql`
    {
        locations {
            userId
            devices {
                uid
                name
                deviceType
                role
                addedOn
                updatedOn
                fwVersion
                credentials {
                    username
                    password
                }
                members {
                    name
                    invitationStatus
                    addedOn
                }
            }
        }
        me {
            id
            groups
        }
        registeredDevices {
            uid
            serialNumber
            deviceType
            updatedOn
        }
    }
`;

export interface GetDefaultLocationQueryResult {
    locations: [{
        userId: string,
        devices: Device[]
    }],
    me: {
        id: string,
        groups: string[]
    },
    registeredDevices: Device[]
}
