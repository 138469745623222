import { Device } from "../../models";
import Axios from "axios";

/**
 * Function to get full device info using the latest Chromecast API from TUTK
 * @param device the intended device to be interviewed/requested
 * @returns the content object with the device info response or string with error
 */
export const onRequestDeviceFullInfo = async (device: Device) => {
    const axiosGetDeviceStatus = Axios.create({
        baseURL: process.env.API_V2
    });

    const eventBody = {
        uid: device.uid,
        key: device.credentials?.username,
        avKey: device.credentials?.password,
    }

    const axiosConfig = {
        timeout: 15000,
    };

    try {
        const result = await axiosGetDeviceStatus.post(
            `${process.env.GET_DEVICE_FULL_INFO_PATH}`,
            eventBody,
            axiosConfig
        );
        return result.data;
    }
    catch (err) {
        return err;
    }
};
