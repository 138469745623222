import React, { useContext, useEffect, useState } from "react"

import { navigate } from "@reach/router"

import { Col, Row, Form, Input, Button, Typography } from "antd"
import { LockOutlined, MailOutlined, UserOutlined } from "@ant-design/icons"

import { CountryCodeFlag } from "../common/country-code-flag"
import { FormattedMessage } from "../../shared/formatted-message.component"
import { IntlContext } from "../../../state/intl.context"
import { register } from "../../../utils/auth/auth-service"
import {
  getCountryNumberCodeFromCountryTextCode,
  getPhoneNumberValidationMessage,
  getMaxPhoneNumberLengthByCountry,
} from "../../../utils/extras/phone.helpers"
import { showNotification } from "../../../utils/extras/notification.helpers"

import "../../shared/global.styles.scss"

const { Title, Text } = Typography

declare global {
  interface Window {
    recaptchaCallback: any
  }
}

export const RegisterForm: React.FC = () => {
  const { formatMessage } = useContext(IntlContext)
  const [form] = Form.useForm()
  if (typeof window !== `undefined`) {
    window.recaptchaCallback = recaptchaCallback
  }

  // LOCAL STATE
  const [loading, setLoading] = useState(false)
  const [phoneValidationStatus, setPhoneValidationStatus] = useState<
    "" | "error" | "success" | "warning" | "validating" | undefined
  >(undefined)
  const [emailValidationStatus, setEmailValidationStatus] = useState<
    "" | "error" | "success" | "warning" | "validating" | undefined
  >(undefined)
  const [usernameValidationStatus, setUsernameValidationStatus] = useState<
    "" | "error" | "success" | "warning" | "validating" | undefined
  >(undefined)
  const [emailConfirmValidationStatus, setEmailConfirmValidationStatus] = useState<
    "" | "error" | "success" | "warning" | "validating" | undefined
  >(undefined)
  const [passwordConfirmValidationStatus, setPasswordConfirmValidationStatus] = useState<
    "" | "error" | "success" | "warning" | "validating" | undefined
  >(undefined)
  const [passwordValidationStatus, setPasswordValidationStatus] = useState<
    "" | "error" | "success" | "warning" | "validating" | undefined
  >(undefined)
  const [countryTextCode, setCountryTextCode] = useState("us")

  // https://stackoverflow.com/questions/34424845/adding-script-tag-to-react-jsx
  useEffect(() => {
    const script = document.createElement("script")

    script.src = "https://www.google.com/recaptcha/api.js"
    script.async = true
    script.defer = true

    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [])

  // SIGN UP SUBMIT HANLDER
  const handleSubmit = (values: any) => {
    // console.log(values)

    if (grecaptcha.getResponse().length !== 0) {
      // console.log(grecaptcha.getResponse())
      // Axios.post("https://www.google.com/recaptcha/api/siteverify", {
      //   secret: process.env.CAPTCHA_SITE_KEY_V2,
      //   response: grecaptcha.getResponse(),
      // })
      //   .then((response) => console.log(response))
      //   .catch((error) => console.log(error))
      form.setFields([
        {
          name: "recaptcha",
          errors: [""],
        },
      ])
    } else {
      form.setFields([
        {
          name: "recaptcha",
          errors: ["You MUST check this to continue."],
        },
      ])
      return
    }

    // If the phone number does not have error
    // if (phoneValidationStatus !== 'error') {
    setUsernameValidationStatus("")
    setEmailValidationStatus("")
    setPhoneValidationStatus("")
    setLoading(true)
    const phoneNumber = `${getCountryNumberCodeFromCountryTextCode(
      values.countryFlag
    )}${values.phoneNumber}`

    register(
      values.firstName,
      values.lastName,
      values.username,
      values.email,
      phoneNumber,
      values.password
    )
      .then((result) => {
        if (typeof result === "object") {
          setLoading(false)
          navigate("/verification-code", {
            state: {
              comingFrom: "register",
              username: values.username,
              password: values.password,
              aliasReceiver: result.CodeDeliveryDetails.Destination,
            },
          })
        } else {
          // Means an error message (username name exist) was received. Notify.
          // ERR_EMAIL_ADDR_IN_USE
          if (result.indexOf("ERR_EMAIL_ADDR_IN_USE") > 0) {
            setEmailValidationStatus("error")
            const emailErrorMsg =
              "This email is already associated with an account."
            form.setFields([
              {
                name: "email",
                errors: [emailErrorMsg],
              },
            ])
          }
          // ERR_PHONE_NUMBER_IN_USE
          if (result.indexOf("ERR_PHONE_NUMBER_IN_USE") > 0) {
            setPhoneValidationStatus("error")
            const phoneErrorMsg =
              "This phone number is already associated with an account."
            form.setFields([
              {
                name: "phoneNumber",
                errors: [phoneErrorMsg],
              },
            ])
          }
          // ERR_USERNAME_IN_USE
          if (
            result.indexOf("ERR_USERNAME_IN_USE") > 0 ||
            result === "Username Already Exist"
          ) {
            const usernameErrorMsg =
              "This username is already taken. Try again."
            setUsernameValidationStatus("error")
            form.setFields([
              {
                name: "username",
                errors: [usernameErrorMsg],
              },
            ])
          }
          setLoading(false)
        }
      })
      .catch((err) => {
        setLoading(false)
        showNotification(
          "error",
          "Something Went Wrong",
          "Something went wrong processing the sign up request."
        )
        console.error(JSON.stringify(err, null, 4))
      })
    // }
  }

  // HANDLE FIELD ONCHANGE
  const handleFieldOnChange = (event: any) => {
    switch (event.target.placeholder) {
      case 'Email':
        form.setFields([
          {
            name: "email",
            errors: [],
          },
          {
            name: "confirmEmail",
            errors: [],
          },
        ])
        break
      case 'Confirm Email':
        form.setFields([
          {
            name: "confirmEmail",
            errors: [],
          },
        ])
        break
      case 'Password':
        form.setFields([
          {
            name: "password",
            errors: [],
          },
          {
            name: "confirmPassword",
            errors: [],
          },
        ])
        break
      case 'Confirm Password':
        form.setFields([
          {
            name: "confirmPassword",
            errors: [],
          },
        ])
        break
      default:
        break;
    }
  }

  // VALIDATE PHONE NUMBER
  const validatePhoneNumber = (_rule: unknown, value: string) => {
    const phoneCountryCode = getCountryNumberCodeFromCountryTextCode(
      form.getFieldValue("countryFlag")
    )
    const regexForCu = /^[0-9]\d{7}$/g
    const regexForTw = /^[0-9]\d{8}$/g
    const regexForUsCaMx = /^[0-9]\d{9}$/g
    const regexForCn = /^[0-9]\d{10}$/g

    if (!value) {
      // TODO: Customize validation messages based on country phone lenght requirements
      return Promise.reject("Please enter your phone number area code first.")
    } else {
      const validationMsg = getPhoneNumberValidationMessage(
        value,
        phoneCountryCode
      )
      switch (phoneCountryCode) {
        case "+1":
          return !regexForUsCaMx.test(value)
            ? Promise.reject(validationMsg)
            : Promise.resolve()
        case "+52":
          return !regexForUsCaMx.test(value)
            ? Promise.reject(validationMsg)
            : Promise.resolve()
        case "+53":
          return !regexForCu.test(value)
            ? Promise.reject(validationMsg)
            : Promise.resolve()
        case "+886":
          return !regexForTw.test(value)
            ? Promise.reject(validationMsg)
            : Promise.resolve()
        case "+86":
          return !regexForCn.test(value)
            ? Promise.reject(validationMsg)
            : Promise.resolve()
        default:
          return Promise.resolve()
      }
    }
  }

  /*const validatePhoneNumberOnBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    let phone = event.target.value;
    const phoneCountryCode = getCountryNumberCodeFromCountryTextCode(
      form.getFieldValue('countryFlag')
    );
    const validationMsg = getPhoneNumberValidationMessage(phone, phoneCountryCode);

    if (validationMsg) {
      setPhoneValidationStatus('error');
      form.setFields([
        {
          name: 'phoneNumber',
          errors: [validationMsg]
        }
      ])
    } else {
      setPhoneValidationStatus('success');
    }
  }*/

  // VALIDATE PASSWORD POLICIES
  const validatePasswordPolicies = (_rule: unknown, value: string) => {
    // TODO: extract this value to an application-level constant
    const passwordPolicy = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,20}$/

    if (!value) {
      return Promise.reject("Please enter your password.")
    } else if (!passwordPolicy.test(value)) {
      return Promise.reject(
        formatMessage({ id: "app.account.change-password.policies" })
      )
    } else {
      return Promise.resolve()
    }
  }

  // COUNTRY FLAG ON CHANGE
  const handlePhoneCountryFlagChange = (value: string) => {
    setCountryTextCode(value)
    form.setFieldsValue({
      phoneNumber: "", // Make the phone input empty if user selects different country code
    })
  }

  // HANDLE CHECK RECAPTCHA
  function recaptchaCallback(response: string) {
    if (response && form.getFieldError(["recaptcha"])) {
      form.setFields([
        {
          name: "recaptcha",
          errors: [""],
        },
      ])
    }
  }

  return (
    <Row className="register-form-wrapper">
      <Col span={24} style={{ alignItems: "center", backgroundColor: "white" }}>
        <Form
          initialValues={{
            countryFlag: "us",
            // firstName: "TestName",
            // lastName: "TestLastName",
            // username: "test",
            // email: "test@emailtest.com",
            // phoneNumber: "5555555555",
            // password: "Test123",
            // confirmPassword: "Test123",
          }}
          form={form}
          className="register-form"
          layout="horizontal"
          onFinish={handleSubmit}
        >
          <Title level={3}>Create an Account</Title>

          {/* PHONE NUMBER */}
          <Form.Item
            name="phoneNumber"
            validateStatus={phoneValidationStatus}
            rules={[
              // Phone numbers from CU have 8 digits
              // Phone numbers from TW have 9 digits
              // Phone numbers from US, MX, CA have 10 digits
              // Phone numbers from CN have 11 digits
              { validator: validatePhoneNumber },
              // { required: true, message: 'Please enter your phone number area code first.' },
            ]}
          >
            <Input
              // onBlur={validatePhoneNumberOnBlur} // TODO
              addonBefore={
                <CountryCodeFlag
                  onChange={handlePhoneCountryFlagChange}
                  inputId="registerCountryFlag"
                />
              }
              style={{ width: "100%" }}
              placeholder="Phone Number"
              maxLength={getMaxPhoneNumberLengthByCountry(countryTextCode)}
              prefix={getCountryNumberCodeFromCountryTextCode(countryTextCode)}
            />
          </Form.Item>

          {/* FIRST AND LAST NAME */}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              // marginTop: "1rem",
            }}
          >
            {/* FIRST NAME */}
            <Form.Item
              name="firstName"
              style={{ marginRight: "0.25rem" }}
              rules={[
                { required: true, message: "First name is required." },
                { pattern: /^[A-Za-z\s]+$/, message: "Verify your name." },
              ]}
            >
              <Input placeholder="First Name" />
            </Form.Item>

            {/* LAST NAME */}
            <Form.Item
              name="lastName"
              style={{ marginLeft: "0.25rem" }}
              rules={[
                { required: true, message: "Last name is required." },
                { pattern: /^[A-Za-z\s]+$/, message: "Verify your last name." },
              ]}
            >
              <Input placeholder="Last Name" />
            </Form.Item>
          </div>

          {/* EMAIL */}
          <Form.Item
            name="email"
            validateStatus={emailValidationStatus}
            validateTrigger='onBlur'
            rules={[
              { required: true, message: "Please enter your email address." },
              { type: "email", message: "This is not a valid email." },
            ]}
          >
            <Input
              placeholder="Email"
              onChange={handleFieldOnChange}
              prefix={<MailOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
            />
          </Form.Item>

          {/* CONFIRM EMAIL */}
          <Form.Item
            name="confirmEmail"
            //dependencies={["email"]}
            validateStatus={emailValidationStatus}
            validateTrigger='onBlur'
            rules={[
              { required: true, message: "Please confirm your email address." },
              // { type: "email", message: "Email is not valid!" },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("email") === value) {
                    return Promise.resolve()
                  }
                  return Promise.reject("The emails MUST match. Try again.")
                },
              }),
            ]}
          >
            <Input
              placeholder="Confirm Email"
              prefix={<MailOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
              onPaste={(e) => e.preventDefault()}
              onChange={handleFieldOnChange}
            />
          </Form.Item>

          {/* USERNAME */}
          <Form.Item
            name="username"
            validateStatus={usernameValidationStatus}
            rules={[
              { required: true, message: "Please enter your username." },
              {
                pattern: /^[A-Za-z\d._]+$/,
                message: "Use letters, numbers, '_', and '.' only.",
              },
              {
                pattern: /^[^._]/,
                message: "Username connot begin with '_' or '.'",
              },
              {
                pattern: /[^._]$/,
                message: "Username connot end with '_' or '.'",
              },
              {
                pattern: /^(?!.*?\.\.)(?!.*?__)(?!.*?_\.)(?!.*?\._)[\w\.\/~!@#$%^\[\]\?\*\-\+\=\(\)\;\:\\,<>\”\`\{\}\|\”]+$/,
                message: "Can not have 2 symbols next to each other.",
              },
              {
                max: 20,
                message: "Username can not exceed 20 characters.",
              },
              { min: 8, message: "Username must be at least 8 characters." },
            ]}
          >
            <Input
              placeholder="Username"
              prefix={<UserOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
            />
          </Form.Item>

          {/* PASSWORD */}
          <Form.Item
            name="password"
            validateTrigger='onBlur'
            rules={[
              { validator: validatePasswordPolicies },
            ]}
          >
            <Input.Password
              placeholder="Password"
              onChange={handleFieldOnChange}
              prefix={<LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
            />
          </Form.Item>

          {/* CONFIRM PASSWORD */}
          <Form.Item
            name="confirmPassword"
            //dependencies={["password"]}
            validateTrigger='onBlur'
            rules={[
              { required: true, message: "Please confirm your new password." },
              // { validator: compareToFirstPassword },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve()
                  }
                  return Promise.reject("The passwords MUST match. Try again.")
                },
              }),
            ]}
          >
            <Input.Password
              placeholder="Confirm Password"
              onChange={handleFieldOnChange}
              prefix={<LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
            />
          </Form.Item>

          {/* PASSWORD POLICIES HELP */}
          {/* TODO: make it as change password feature validation */}
          <div
            style={{
              textAlign: "center",
              lineHeight: "normal",
            }}
          >
            <Text>
              Password must be no less than 8 characters and include at least
              one number, one uppercase letter, and one lowercase letter.
            </Text>
          </div>

          {/* GOOGLE reCAPTCHA */}
          <Form.Item
            style={{
              marginTop: "1rem",
              width: "100%",
              height: "5rem",
            }}
            name="recaptcha"
          >
            {/* <React.Fragment>
              <Helmet>
                <script
                  // src={`https://www.google.com/recaptcha/api.js?r=${Math.random()}`}
                  src="https://www.google.com/recaptcha/api.js"
                  async
                  defer
                ></script>
              </Helmet>
              <div
                className="g-recaptcha"
                data-sitekey={process.env.CAPTCHA_SITE_KEY_V2}
                data-callback="recaptchaCallback"
              ></div>
            </React.Fragment> */}

            <div
              className="g-recaptcha"
              data-sitekey={process.env.GATSBY_CAPTCHA_SITE_KEY_V2}
              data-callback="recaptchaCallback"
            />
          </Form.Item>

          {/* REGISTER BUTTON */}
          <Form.Item style={{ marginTop: "2rem" }}>
            <Button
              // icon={loading && <LoadingOutlined />}
              loading={loading}
              className="register-form-submit-button"
              type="primary"
              htmlType="submit"
              // disabled={hasErrors(getFieldsError())}
              block
            >
              <FormattedMessage id="app.auth.signup" />
            </Button>
          </Form.Item>

          {/* TERMS AND DATA POLICY */}
          <div
            style={{
              textAlign: "center",
              letterSpacing: "-0.25px",
              lineHeight: "normal",
            }}
          >
            <Text style={{ fontSize: "10px" }}>
              By clicking Sign Up, you agree to our
              <a
                href="https://nightowlsp.com/pub/forms/software-app-privacy-policy.html"
                target="_blank"
                className="policy-link"
              >
                Terms and Data Policy.
              </a>
              You may receive SMS Notifications from us and can opt out any
              time.
            </Text>
          </div>
        </Form>
      </Col>
    </Row>
  )
}
