import React from 'react';
import { useSelector } from 'react-redux';
import { AppState, UserState } from '../../state';
import { Link } from '@reach/router';

import { Layout, Grid } from 'antd';
import { InlineLoginForm } from '../form/login/inline-login-form';
import { AccountMenu } from '../account/account-menu';
import { LoginSignupButtons } from '../landing/login-signup-buttons'
import { parseTwoDigitYear } from 'moment';
import * as queryString from 'query-string'

const { useBreakpoint } = Grid

// import LogoImg from '/images/siteIcons/no-logo/2018-logo-grey-logo-2@3x.png';
// import LogoText from '/images/siteIcons/no-logo/combined-shape@3x.png';

export const HeaderLayout: React.FC = () => {

    const screen = useBreakpoint()

    //console.log('Screen breakpoint:',JSON.stringify(screen))
    //console.log('Medium:',screen.md)
    
    const { user } = useSelector<AppState, UserState>(state => state.user);
    let parsed = undefined
    if (typeof location != 'undefined') {
        parsed = queryString.parse(location.search)
    }

    return (
        <Layout.Header style={{display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'space-between',
        // Makes Header fixed
        position: 'fixed',
        top: '0',
        width: '100%',
        backgroundColor: 'var(--lightBlue)',
        zIndex: 1,
        padding: screen.md ? '0 50px' : '0 10px'}}>
            <div className="logo" style={{ display: 'inline' }}>
                <Link to="/" id="logo-picture">
                    <img
                        style={{
                            width: '4rem',
                            height: 'auto',
                            marginRight: '1rem'
                        }}
                        // src="/images/siteIcons/no-protect-icon/group@3x.png"
                        // src="/images/siteIcons/no-logo/2018-logo-grey-logo-2@3x.png"
                        src="/images/siteIcons/no-logo/2018-logo-grey-logo-2@3x_OLD.png"
                        alt="Night Owl Logo"
                    />
                </Link>
                <Link to="/" id="logo-text">
                    <img
                        style={{ width: '8rem', height: 'auto' }}
                        src="/images/siteIcons/no-logo/combined-shape@3x.png"
                        alt="Night Owl Text"
                    />
                </Link>
            </div>
            { user.Username || typeof parsed != 'undefined' && parsed.access_token ?
                <AccountMenu /> : (
                screen.xl ?
                <InlineLoginForm layout='inline' /> :
                <LoginSignupButtons />
            )}
        </Layout.Header>
    );
};
