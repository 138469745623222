import { User } from "../../models";
import { isValidToken } from "../../utils/auth/auth-service";

let userData = undefined;
if (typeof localStorage !== 'undefined') {
    const userDataFromLS = localStorage.getItem('user_data');
    const parsedUserData = userDataFromLS && JSON.parse(userDataFromLS) as User;
    
    // let userData = parsedUserData;
    
    // Set initial state from the local sotrage in case user refresh the page
    // if (parsedUserData && isValidToken(parsedUserData.AccessToken)) {
    if (parsedUserData) {
        userData = parsedUserData;
        // createLog('INITIAL STATE: ', JSON.stringify(userData, null, 2));
    }
}


export interface UserState {
    user: User;
}

export const initialUserState: UserState = {
    user: {
        Username: userData ? userData.Username : '',
        AccessToken: userData ? userData.AccessToken : '',
        RefreshToken: userData ? userData.RefreshToken : '',
        Profile: userData ? userData.Profile : undefined
    }
    // user: {
    //     Username: '',
    //     AccessToken: '',
    //     RefreshToken: '',
    //     Profile: undefined
    // }
};

