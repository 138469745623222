import { LegacyDevice } from '../../models';

// Type interface
export interface LegacyDevicesState {
  legacyListLoading: boolean;
  legacyItems: LegacyDevice[];
};

export const initialLegacyDevicesState: LegacyDevicesState = {
  legacyListLoading: true,
  legacyItems: [],
};