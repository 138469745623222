import { Action } from "redux";

export interface SetIsLoadingDevicesAction extends Action {
    type: "SetIsLoadingDevices";
    payload: boolean;
}

export const setIsLoadingDevices = (isLoadingDevices: boolean): SetIsLoadingDevicesAction => ({
    type: "SetIsLoadingDevices",
    payload: isLoadingDevices
});
