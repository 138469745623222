import { IntlShape } from "../state/intl.context";

export const es: IntlShape = {
    locale: 'es',
    messages: {
        // global app-level strings
        'app.title': 'Night Owl Protect',
        'app.content.info.bold': 'Manage Your Night Owl Devices',
        'app.content.info': 'Sign up to register and manage your device.',
        'app.content.app.info.header': 'Download it now',
        'app.content.app.info.description': 'Night Owl Protect app.',
        'app.section-under-construction': 'This section is under construction. Please excuse our mess while we build something awesome for you 😊',
        'app.loading.please-wait': 'Please wait...',
        'app.footer.copyright': '© ' + new Date().getFullYear() + ' Night Owl Security Products',
        'app.footer.title': 'Need Help?',
        'app.footer.faq.title': 'FAQs',
        'app.footer.faq.link': 'Check our FAQs section!',
        'app.footer.faq-link.forgot-password': 'I forgot my device password',
        'app.footer.faq-link.register-device': 'How do I register my DVR / NVR?',
        'app.loading': 'Loading...',
        'app.network.error': 'ERROR. Please check your Internet access.',
        'app.general.error': 'Ops! Something went wrong.',
        // FAQs content
        'app.faqs.device-password-reset.content.title': 'I forgot my device password',
        'app.faqs.device-password-reset.content.bold-text-p1': 'My DVR/NVR is not connected to the Internet or it hasn’t been logged in with my Night Owl Protect account.',
        'app.faqs.device-password-reset.content.bold-text-p2': 'My DVR/NVR is connected to the Internet.',
        'app.faqs.device-password-reset.content.p1-content':
            'Click on Forgot password in the Log In pop up from your DVR. You will notice that it will ask you for a Reset Code in order to create a new password. In order to get that code, log in to your Night Owl Protect account {here} or if you don’t have an account create one {here} and register your device. Once your device is registered, click on the card displaying your device and on the Details right side panel, you will see a Reset Code button. Click on it and enter the date that is being displayed in your DVR/NVR. Once you have entered it click OK and the platform will give you a Reset code. Then go back to your DVR/NVR and enter this code. It will verify it and then you will be able to create a new password.',
        'app.faqs.device-password-reset.content.p1-content-link': 'here',
        'app.faqs.device-password-reset.content.p2-content':
            'If you forgot your password and your DVR/NVR is logged in with your Night Owl Protect account, go the Night Owl Protect mobile app and in the Log In screen tap on Forgot my password and follow the instructions to create a new password. Once you have created it, log in with it in your DVR/NVR.',
        'app.faqs.register-device.content.title': 'How do I register my DVR / NVR?',
        'app.faqs.register-device.content.p1':
            'Registering as the device owner is essential to protect and secure your privacy. It also helps our Tech Support team better assist you if needed.',
        'app.faqs.register-device.content.p2':
            'Register your device by downloading the Night Owl Protect App from your device\'s Play Store and creating an account. Use your Night Owl Protect login credentials to log into your DVR / NVR that is connected to the Internet. If your DVR / NVR is not connected to the Internet, click the link below to register your device.',
        // Login/Registration page
        'app.auth.welcome-message': 'Hi {username}!',
        'app.auth.menu.option.devices': 'My devices',
        'app.auth.menu.option.account': 'Manage my account',
        'app.auth.menu.option.plans': 'Cloud Plans',
        'app.auth.get-started': 'Get Started',
        'app.auth.login': 'Log In',
        'app.auth.logging-ing': 'Logging In',
        'app.auth.logout': 'Log Out',
        'app.auth.signup.create-an-account': 'Create an Account',
        'app.auth.signup': 'Sign Up',
        'app.auth.signup.submit': 'Submit',
        'app.auth.signup.verify-phone-title': 'Please verify your phone number',
        'app.auth.signup.verify-phone-content': 'Type the confirmation code we sent you in an SMS.',
        'app.auth.signup.password-policy': 'Passwords must be no less than 8 characters\nlong and include at least one number, one uppercase\nletter, and one lowercase letter.',
        'app.auth.signup.agreement': 'By clicking Sign Up, you agree to our Terms, Data Privacy Policy, and Cookies Policy. You may receive SMS Notifications from us and can opt-out at any time.',
        // Titles for the different states of the Registration form in /login
        'app.auth.signup.registration-title.Normal': 'Create an Account',
        'app.auth.signup.registration-title.Loading': 'Please wait...',
        'app.auth.signup.registration-title.PhoneConfirmation': 'SMS Verification',
        // Device Types
        'device.doorbell': 'Doorbell',
        'device.dvr': 'Video Recorder',
        'device.standalone': 'Standalone IPC',
        // Devices page
        'app.devices.registered-devices': 'Registered Devices',
        'app.devices.registered-devices.content': 'The following devices are registered, but have not been added to Night Owl Protect. Follow the steps in your device\'s Quick Setup Guide to add your device to the Night Owl Protect App.',
        'app.devices.register-new-device': 'Register New Device',
        'app.devices.added-devices': 'Added Devices',
        'app.devices.added-devices.content': 'The following registered devices were added to your Night Owl Protect account.',
        'app.devices.shared-devices': 'Shared Devices',
        'app.devices.shared-devices.content': 'The following devices have been shared with you from another account.',

        // Device Page -> Register Notification
        'app.devices.registered-devices.notification.success.title': 'Device Registered!',
        'app.devices.registered-devices.notification.success.description': 'Your device has been registered successfully.',
        // Device Card
        'app.devices.actions.view-details': 'DETAILS',
        'app.devices.aria.device-icon': 'Device Icon',
        // Device Page -> Register Modal
        'app.devices.modal.register-device.title': 'Register a new device',
        'app.devices.modal.register-device.content': 'Please, enter your device\'s 20 characters UID.',
        'app.devices.modal.register-device.input.placeholder': 'Enter UID',
        'app.devices.modal.register-device.input.help': 'The UID is located on the device\'s label.',
        'app.devices.modal.register-device.input.status.registered.error': 'This UID is already REGISTERED to your account.',
        'app.devices.modal.register-device.input.status.added.error': 'This UID is already ADDED to your account.',
        // 'app.devices.modal.register-device.input.status.added-other.error': 'This UID belongs to another device. Please, check and try again.', // Not shown for security reasons
        'app.devices.modal.register-device.input.status.unrecognize.error': 'This UID is NOT recognized by our system. Please, check and try again.',
        'app.devices.modal.register-device.input.status.empty.error': 'Please, provide a valid UID.',
        'app.devices.modal.register-device.input.status.invalid.error': 'Must be a combination of 20 alphanumeric characters.',
        'app.devices.modal.register-device.cancel-btn': 'Cancel',
        'app.devices.modal.register-device.ok-btn': 'OK',
        // Device Details
        'app.details.no-device.title': 'Select a device to show its details.',
        'app.details.device.title': 'Details',
        'app.details.device.section.info': 'Info',
        'app.details.device.info.name': 'Device Name: ',
        'app.details.device.info.no-name': '[NO NAME]',
        'app.details.device.info.type': 'Device Type: ',
        'app.details.device.info.role': 'Your Role: ',
        'app.details.device.members.title': 'Shared Users',
        'app.details.device.members.note': 'NOTE: Use our Night Owl Protect Mobile App to add/delete members to your device.',
        'app.details.device.section.settings': 'Settings',
        'app.details.device.section.cloud': 'Cloud Service',
        'app.details.device.section.actions': 'Actions',
        'app.details.device.actions.text': 'If you forgot your device local password, click on “Get reset code” to reset it.',
        'app.details.actions.reset-password': 'Get Reset Code',
        // Device Details Page -> Reset Code Modal
        'app.details.modal.reset-code.title': 'Password Reset',
        'app.details.modal.reset-code.content': 'Enter the date that appears on your DVR / NVR’s Live View screen.',
        'app.details.modal.reset-code.label': 'Date',
        'app.details.modal.reset-code.cancel-btn': 'Cancel',
        'app.details.modal.reset-code.ok-btn': 'Get Code',
        'app.details.modal.reset-code.date.error': 'Please enter a valid date.',
        'app.details.modal.reset-code.date.help': 'NOTE: Your system must be connected to a TV / Monitor to access the date. TV / Monitor must be at least 1080p resolution.',
        // Device Details Page -> Reset Code Notification
        'app.details.notification.reset-code.success.title': 'RESET CODE',
        'app.details.notification.reset-code.success.content': 'The reset code for your device ',
        'app.details.notification.reset-code.success.content.is': ' is: ',
        // Device Details Page -> Change Name Notifications
        'app.details.notification.change-name.success.title': 'NAME CHANGED!',
        'app.details.notification.change-name.success.content': 'Your device\'s name has been changed.',
        'app.details.notification.change-name.error.title': 'INVALID NAME',
        'app.details.notification.change-name.error.content': 'Please, provide a valid name for your device.',
        // Account Page
        'app.account.info.title': 'Account',
        'app.account.settings.title': 'Settings',
        // Account Page -> Change Password Modal
        'app.account.change-password.modal.title': 'Change Password',
        'app.account.change-password.modal.ok-btn': 'Change Password',
        'app.account.change-password.modal.cancel-btn': 'Cancel',
        'app.account.change-password.modal.current.placeholder': 'Current Password',
        'app.account.change-password.modal.new.placeholder': 'New Password',
        'app.account.change-password.modal.confirm.placeholder': 'Confirm Password',
        'app.account.change-password.modal.help.note': 'Note: ',
        'app.account.change-password.modal.help.note.text': 'New Password field MUST containing at least: ',
        'app.account.change-password.modal.help.uppercase': 'A upper case letter.',
        'app.account.change-password.modal.help.lowercase': 'A lower case letter.',
        'app.account.change-password.modal.help.number': 'A number.',
        'app.account.change-password.modal.help.length': 'Minimum of 8 characters.',
        // Account Page -> Change Password Input validation help text 
        'app.account.change-password.current': 'Your current password does not meet our policies.',
        'app.account.change-password.new': 'Password is not valid.',
        'app.account.change-password.confirm': 'Password does not match.',
        // Account Page -> Change Password Notification
        'app.account.change-password.notification.title': 'Password Changed!',
        'app.account.change-password.notification.description': 'Your password has been changed successfully.',
        'app.account.change-password.notification.error.title': 'Error. Limit Exceeded.',
        'app.account.change-password.notification.error.description': 'You have reached the maximum number of attempts. Try again later.',
        // Account Page -> Verification Code Notifications
        'app.account.verify-code.notification.error.code-mismatch.title': 'ERROR. Code Mismatch!',
        'app.account.verify-code.notification.error.code-mismatch.description': 'The code you provided does not match.',
        'app.account.verify-code.notification.error.code-expired.title': 'ERROR. Code Expired!',
        'app.account.verify-code.notification.error.code-expired.description': 'The code you provided is already expired.',
        // Error Messages
        'app.error.page-not-found-title': 'Page Not Found',
        'app.error.page-not-found': 'The page you\'re looking for could not be located'
        // TODO: add other strings here
    }
}
