import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { HttpLink } from 'apollo-link-http';
import { ApolloLink } from 'apollo-link';
import { User } from '../models';
import fetch from 'isomorphic-fetch';

// export const getApolloClient = (resolveAccessToken: () => string) => {
export const getApolloClient = () => {

    const authLink = new ApolloLink((operation, forward) => {
        // Retrieve the Access Token token from local storage.
        // const token = resolveAccessToken();
        const userDataFromLS = localStorage.getItem('user_data');
        const parsedUserData = userDataFromLS && JSON.parse(userDataFromLS) as User;
        const token = parsedUserData && parsedUserData.AccessToken;

        // Use the setContext method to set the HTTP headers.
        operation.setContext({
            headers: {
                authorization: token
            }
        });

        // Call the next link in the middleware chain.
        return forward(operation);
    });

    const httpLink = new HttpLink({
        uri: process.env.NOP_API_ENDPOINT,
        fetch
    });
    const apiClient = new ApolloClient({
        cache: new InMemoryCache(),
        link: authLink.concat(httpLink),
        name: 'com.nightowlsp.nop.webportal',
        version: '1.0'
        
    });
    return apiClient;
};

// import ApolloClient from 'apollo-boost';
// import { InMemoryCache } from 'apollo-cache-inmemory';
// import { User } from '../models';

// export const apolloClient: ApolloClient<any> = new ApolloClient({
//     uri: process.env.NOP_API_ENDPOINT,
//     request: (operation) => {
//       // get the authentication token from local storage if it exists
//       // https://www.apollographql.com/docs/react/networking/authentication/
//       const userDataFromLS = localStorage.getItem('user_data');
//       const parsedUserData = userDataFromLS && JSON.parse(userDataFromLS) as User;
//       const token = parsedUserData && parsedUserData.AccessToken;

//       operation.setContext({
//         headers: {
//           authorization: token
//         },
//         cache: new InMemoryCache(),
//         name: 'com.nightowlsp.nop.webportal',
//         version: '1.0'
//       })
//     }
// });