import gql from 'graphql-tag';

import {  } from 'apollo-client';

import { DeviceMember } from './device-member.dto';

export const updateDeviceMutation = gql`
    mutation updateDevice($uid: ID!, $name: String!) {
        updateDevice (data: {uid: $uid, name: $name}) {
            uid
            name
            deviceType
        }
    }
`;

export interface MutationUpdateDeviceResults {
    updateDevice: {
        uid: string,
        name: string,
        deviceType: string,
        role: string,
        members: [DeviceMember]
    }
}