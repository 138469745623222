import React from 'react'
import { InlineLoginForm } from '../../form/login/inline-login-form'
import { Drawer } from 'antd'

interface RegisterFormModalProps {
  visible: boolean
  closeDrawer: () => void
}

 export const LoginFormModal: React.FC<RegisterFormModalProps> = (props) => {
   const { visible, closeDrawer } = props
  
  return (
    <Drawer
      closeable={true}
      placement='right'
      title="Login"
      width='100%'
      onClose={closeDrawer}
      visible={visible}
    >
      <InlineLoginForm layout='horizontal' />
    </Drawer>
  )
}