import { IntlShape } from "../state/intl.context";

export const en: IntlShape = {
    locale: 'en',
    messages: {
      // Landing and global app-level strings
      'app.warning.stop': 'STOP: ',
      'app.warning.content':
        'For security purposes, we DO NOT recommend using this site from a shared or public device.',
      'app.title': 'Thank you for Choosing Night Owl!',
      'app.content.info.bold': 'Manage Your Night Owl Devices',
      'app.content.info.p1': 'If you have already created a Night Owl Protect account, simply log in using your existing credentials. For ALL other Night Owl devices using different mobile apps, please create an account.',
      'app.content.info.p2': 'This web portal makes managing your devices easy.',
      'app.content.app.info.header': 'Download it now',
      'app.content.app.info.description': 'Night Owl Protect app.',
      'app.section-under-construction':
        'This section is under construction. Please excuse our mess while we build something awesome for you 😊',
      'app.loading.please-wait': 'Please wait...',
      'app.footer.copyright':
        '© ' + new Date().getFullYear() + ' Night Owl SP, LLC',
      'app.footer.title': 'Need Help?',
      'app.footer.faq.title': 'FAQs',
      'app.footer.faq.link': 'Check our FAQs section!',
      'app.footer.faq-link.forgot-password': 'I forgot my device password',
      'app.footer.faq-link.register-device': 'How do I register my DVR/NVR?',
      'app.loading': 'Loading...',
      // 'app.network.error': 'ERROR. Please check your Internet access.',
      'app.network.error':
        'Ops! Something went wrong. Please check your Internet access and refresh the page.',
      'app.general.error.title': 'Unexpected Error!',
      'app.general.error': 'Ops! Something went wrong.',
      'app.unauthorized.error.title': 'Session Expired!',
      'app.unauthorized.error': 'Your session has expired. Redirecting...',
      'app.too-many-requests.error':
        'Too many requests, your account may be locked.',
      'app.limite-exceded.error':
        'You have reach the maximum number of attempts.',
      // FAQs content
      'app.faqs.device-password-reset.content.title':
        'I forgot my device password',
      'app.faqs.device-password-reset.content.bold-text-p1':
        'My DVR/NVR is not connected to the Internet or it hasn’t been logged into using my Night Owl Protect account credentials. ',
      'app.faqs.device-password-reset.content.underline-text-p1':
        'Follow these steps:',
      'app.faqs.device-password-reset.content.bold-text-p2':
        'My DVR/NVR is connected to the Internet. ',
      'app.faqs.device-password-reset.content.p1-content-1':
        '1) Click \"Forgot password\" in the DVR/NVR login screen.\n', 
      'app.faqs.device-password-reset.content.p1-content-2':
        '2) To receive a reset code, log into your Night Owl Protect account, or create one and register your device.',
      'app.faqs.device-password-reset.content.p1-content-3':
        '3) Once registered, click on the device\'s panel.',
      'app.faqs.device-password-reset.content.p1-content-4':
        '4) Click the "Reset Code" button on the right side of the panel.',
      'app.faqs.device-password-reset.content.p1-content-5':
        '5) Enter the date that is currently displaying on the device\'s Live View. ',
      'app.faqs.device-password-reset.content.p1-content-6':
        '6) Click "Ok" to receive a Reset Code.',
      'app.faqs.device-password-reset.content.p1-content-7':
        '7) Return to the DVR/NVR and enter the code. ',
      'app.faqs.device-password-reset.content.p1-content-8':
        '8) Once verified, you can now create a new password.',
      'app.faqs.device-password-reset.content.p1-content-link': 'here',
      'app.faqs.device-password-reset.content.p2-content-1':
        '1) If you forgot your password and are logged into the DVR/NVR with your Night Owl Protect account credentials, go to the app to reset the password.',
      'app.faqs.device-password-reset.content.p2-content-2':
        '2) Tap "Forgot my password" on the Night Owl Protect app login page.',
      'app.faqs.device-password-reset.content.p2-content-3':
        '3) After resetting, log into your DVR/NVR with the new password.',
      'app.faqs.register-device.content.title': 'How do I register my DVR/NVR?',
      'app.faqs.register-device.content.p1':
        'As the owner, it is important that you register your product to protect and secure your privacy. It also helps our Tech Support team better assist you if needed.',
      'app.faqs.register-device.content.bold-text-p1':
        'My DVR/NVR is connected to the Internet. ',
      'app.faqs.register-device.content.underline-text-p1':
        'Follow these steps to register:',
      'app.faqs.register-device.content.p1-content-1':
        '1) Download the Night Owl Protect app on your Smart Device from the App Store or Google Play Store.',
      'app.faqs.register-device.content.p1-content-2':
        '2) Create an account.',
      'app.faqs.register-device.content.p1-content-3':
        '3) Use your Night Owl Protect login credentials to log into your DVR/NVR.',
      'app.faqs.register-device.content.bold-text-p2':
        'My DVR/NVR is NOT connected to the Internet. ',
      'app.faqs.register-device.content.p2-content-1':
        '1) Login with your Night Owl Protect account or click ',
      'app.faqs.register-device.content.p2-content-1.1':
        ' to create a new one.',
      'app.faqs.register-device.content.p2-content-2':
        '2) Click on "Register New Device".',
      'app.faqs.register-device.content.p2-content-3':
        '3) Click on "Register Offline Device".',
      'app.faqs.register-device.content.p2-content-4':
        '4) Enter your Device’s UID number and click OK.',
      // Login/Registration page
      // 'app.auth.welcome-message': 'Hi {username}!',
      'app.auth.welcome-message': 'Hi ',
      'app.auth.menu.option.devices': 'My devices',
      'app.auth.menu.option.account': 'Manage my account',
      'app.auth.menu.option.support': 'Support',
      'app.auth.menu.option.plans': 'Cloud Plans',
      'app.auth.get-started': 'Get Started',
      'app.auth.login': 'Log In',
      'app.auth.login.form.user-input.placeholder': 'Username or Email',
      'app.auth.login.form.password-input.placeholder': 'Password',
      'app.auth.logging-ing': 'Logging In',
      'app.auth.logout': 'Log Out',
      'app.auth.signup.create-an-account': 'Create an Account',
      'app.auth.signup': 'Sign Up',
      'app.auth.signup.submit': 'Submit',
      'app.auth.signup.verify-phone-title': 'Please verify your phone number',
      'app.auth.signup.verify-phone-content':
        'Type the confirmation code we sent you in an SMS.',
      'app.auth.signup.password-policy':
        'Passwords must be no less than 8 characters\nlong and include at least one number, one uppercase\nletter, and one lowercase letter.',
      'app.auth.signup.agreement':
        'By clicking Sign Up, you agree to our Terms, Data Privacy Policy, and Cookies Policy. You may receive SMS Notifications from us and can opt-out at any time.',
      // Titles for the different states of the Registration form in /login
      'app.auth.signup.registration-title.Normal': 'Create an Account',
      'app.auth.signup.registration-title.Loading': 'Please wait...',
      'app.auth.signup.registration-title.PhoneConfirmation': 'SMS Verification',
      // Device Types
      'device.doorbell': 'Video Doorbell',
      'device.dvr': 'Digital Video Recorder',
      'device.gateway': 'Wireless Gateway',
      'device.hybrid': 'Hybrid Digital Video Recorder',
      'device.nvr': 'Network Video Recorder',
      'device.wnvr': 'Wireless Network Video Recorder',
      'device.standalone': 'Wireless IP Camera',
      'device.safe': 'Smart Safe',
      'device.legacy': 'Video Recorder',
      // Devices page
      'app.devices.devices-section.title': 'My Devices',
      'app.devices.devices-section.content.title':
        'Manage Your Night Owl Devices',
      'app.devices.devices-section.content':
        'Register your device and access our password reset feature.',
      'app.devices.register-new-device': 'Register New Device',
      'app.devices.added-devices': 'Added Devices',
      'app.devices.added-devices.content':
        'The following devices were added or shared with you to your Night Owl Protect account.',
      'app.devices.shared-devices': 'Shared Devices',
      'app.devices.shared-devices.content':
        'The following devices have been shared with you from another account.',
      // Device Page -> Register Notification
      'app.devices.registered-devices.notification.success.title':
        'Congratulations!',
      'app.devices.registered-devices.notification.success.description.t1':
        'Your device has been registered. To reset your device’s password, click on',
      'app.devices.registered-devices.notification.success.description.t2':
        ' “View Details” ',
      'app.devices.registered-devices.notification.success.description.t3':
        'next to your device.',
      // Device Card
      'app.devices.actions.view-details': 'VIEW DETAILS',
      'app.devices.body.device-icon': 'Device Icon',
      'app.devices.body.device-info-icon':
        'Someone has shared this devices with you. Some functionalities are not available.',
      'app.devices.body.device-info-label.uid': 'UID: ',
      'app.devices.body.device-info-label.legacy-uid': 'UID / ID: ',
      'app.devices.body.device-info-label.sn': 'SN: ',
      'app.devices.body.device-info-label.role': 'Role: ',
      'app.devices.body.device-info-label.model': 'Model #: ',
      // Device Page -> INFO Register Modal
      'app.devices.modal.info-register-device.content.p1':
        'Register now to easily manage your device.',
      'app.devices.modal.info-register-device.content.p2':
        'Forgot your device password? No worries!',
      'app.devices.modal.info-register-device.content.list1':
        'Reset your Night Owl Protect Account password',
      'app.devices.modal.info-register-device.content.list2':
        'Reset your password for other Night Owl devices (NOT the app)',
      'app.devices.modal.info-register-device.content.list3':
        'View device details',
      'app.devices.modal.info-register-device.ok-btn': 'Register Device',
      // Device Page -> Register Modal
      'app.devices.modal.register-device.title': 'Register your Device',
      'app.devices.modal.register-legacy-device.title': 'Series and Component Model',
      'app.devices.modal.register-device.content':
        'Find the support label located on your device and enter the 1st 6 digits of the UID / ID below. The exact appearance of the support label will vary by model, but will contain the UID / ID.',
      'app.devices.modal.register-device.content.note.bold':
        'NOTE: ',
      'app.devices.modal.register-device.content.note':
        'If you do not see the support label on the top of your device, check the bottom to retrieve the UID / ID.',
      'app.devices.modal.register-legacy-device.content':
        'Please add the device to your account. The DVR/NVR device has to be connected to a TV / Monitor and you MUST be in front of the device to complete the following steps:',
      'app.devices.modal.register-device.content.stop': 'STOP! ',
      'app.devices.modal.register-device.content.stop.description':
        'You MUST enter the 1st 6 digits of your UID / ID exactly as it is on your device’s support label. If entered wrong, you cannot reset your device’s password.',
      'app.devices.modal.register-device.uid-input.label':
        'Type in the 1st 6 digits of your UID / ID',
      'app.devices.modal.register-device.confirm-uid-input.label':
        'Confirm 1st 6 digits of UID / ID',
      'app.devices.modal.register-legacy-device.series.label':
        'Select your Device\'s Series Name:',
      'app.devices.modal.register-legacy-device.model.label':
        'Select your Component Model #:',
      'app.devices.modal.register-device.input.placeholder': 'Enter device UID',
      'app.devices.modal.register-device.input.help':
        "The UID is located on the device's label.",
      'app.devices.modal.register-device.input.status.error':
        'Oops, this UID / ID is already registered. Try again.',
      'app.devices.modal.register-device.input.status.added.error':
        'This UID is already ADDED to your account.',
      // 'app.devices.modal.register-device.input.status.added-other.error': 'This UID belongs to another device. Please, check and try again.', // Not shown for security reasons
      'app.devices.modal.register-device.input.status.unrecognize.error':
        'This UID is NOT recognized by our system. Please, check and try again.',
      'app.devices.modal.register-device.input.status.empty.error':
        'Please, provide a valid UID.',
      'app.devices.modal.register-device.uid-input.status.invalid.error':
        'Oops, the 1st 6 digits of your UID / ID MUST match the label.',
      'app.devices.modal.register-device.confirm-uid-input.status.invalid.error':
        'Oops, the UID / IDs do NOT match. Please try again.',
      'app.devices.modal.register-device.cancel-btn': 'Cancel',
      'app.devices.modal.register-device.ok-btn': 'OK',
      // Device Details
      'app.details.no-device.title': 'Select a device to show its details.',
      'app.details.device.title': 'Details',
      'app.details.device.section.info': 'Info',
      'app.details.device.info.name': 'Device Name: ',
      'app.details.device.info.no-name': '[NO NAME]',
      'app.details.device.info.status': 'Loading...',
      'app.details.device.info.type': 'Type: ',
      'app.details.device.info.addedon': 'Added On: ',
      'app.details.device.info.registeredOn': 'Added On: ',
      'app.details.device.info.uid': 'UID / ID: ',
      'app.details.device.info.sn': 'Serial Number: ',
      'app.details.device.info.fw': 'FW Version: ',
      'app.details.device.info.model': 'Component Model #: ',
      'app.details.legacy-device.info.series': 'Series: ',
      'app.details.legacy-device.info.model': 'Component Model #: ',
      'app.details.legacy-device.info.app': 'Mobile App: ',
      'app.details.device.info.role': 'Your Role: ',
      'app.details.device.info.members': 'Shared Users',
      'app.details.device.members.note': 'NOTE: Use the Night Owl Protect App to add and delete Shared Users.',
      'app.details.device.members.addedon': 'Added On: ',
      'app.details.device.section.settings': 'Password Reset Options',
      'app.details.device.section.help': 'Need Help?',
      'app.details.device.settings.password': 'DVR / NVR Password Reset: ',
      'app.details.device.settings.password.text':
        'If you forgot your device\'s password, click on ',
      'app.details.device.settings.password.text.bold':
        '"Reset Code."',
      'app.details.legacy-device.settings.app-password': 'Reset Mobile App Password:',
      'app.details.legacy-device.settings.app-password.text': 
        'If you forgot your mobile app password, click on ',
        'app.details.legacy-device.settings.app-password.text.bold': 
        '"Learn More."',
      'app.details.device.settings.need-help.text': 
        'Visit our support site for your device\'s manual, troubleshooting guides, video tutorials and more!',
      'app.details.device.settings.live-view': 'Live View: ',
      'app.details.device.settings.playback': 'Playback: ',
      'app.details.device.section.cloud': 'Cloud Plan: ',
      'app.details.device.section.cloud.no-plan': 'None',
      'app.details.device.section.cloud.plan-basic': 'Basic',
      'app.details.device.section.cloud.plan-intermediate': 'Intermediate',
      'app.details.device.section.cloud.plan-pro': 'Pro',
      'app.details.actions.reset-password': 'Reset Code',
      'app.details.actions.reset-account-password': 'Learn More',
      'app.details.actions.open-live-view': ' Open Live View',
      'app.details.actions.open-playback': ' Open Play Back',
      'app.details.actions.cloud.get-plan': 'Get Plan',
      // App Password reset information modal
      'app.modal.cancel-btn': 'Close',
      // Device Details Page -> Reset Code Modal
      'app.details.modal.reset-code.title': 'Password Reset',
      'app.details.modal.reset-code.content':
        'Enter the date that appears on your DVR / NVR’s Live View screen.',
      'app.details.modal.reset-code.label': 'Date',
      'app.details.modal.reset-code.cancel-btn': 'Cancel',
      'app.details.modal.reset-code.ok-btn': 'Get Code',
      'app.details.modal.reset-code.date.error': 'Please enter a valid date.',
      'app.details.modal.reset-code.date.help':
        'NOTE: Your system must be connected to a TV / Monitor to access the date. TV / Monitor must be at least 1080p resolution.',
      // Device Details Page -> Reset Code Notification and Modal Info
      'app.reset-code.modal.info.success.title': 'Password Reset Code',
      'app.reset-code.modal.info.success.content.paragraph':
        'The secure code to reset your device password is below. If the 1st 6 digits of  your UID / ID do not match the UID / ID on your device\'s support label, this reset code will not work. Ensure your device is connected to a TV / Monitor and proceed to the sign in screen. In the password reset code field enter the secure code. ',
      'app.reset-code.modal.info.success.content.paragraph.legacy':
        'Once the password has been reset, click the link below for instructions on re-adding the device to your mobile app.',
      'app.reset-code.modal.info.success.content.code': 'Your Secure Code',
      'app.reset-code.modal.info.success.content.link': 'Click Here For Password Reset Instructions',
      // Device Details Page -> Change Name Notifications
      'app.details.notification.change-name.success.title': 'NAME CHANGED!',
      'app.details.notification.change-name.success.content':
        "Your device's name has been changed.",
      'app.details.notification.change-name.error.title': 'INVALID NAME',
      'app.details.notification.change-name.error.content':
        'Please, provide a valid name for your device.',
      // Account Page
      'app.account.info.title': 'Account',
      'app.account.info.personal.section': 'Personal Information',
      'app.account.info.personal.username.title': 'Username: ',
      'app.account.info.personal.fullname.title': 'Full Name: ',
      'app.account.info.phone': 'Phone: ',
      'app.account.info.phone.verify-btn': 'Verify Phone',
      'app.account.info.email': 'Email: ',
      'app.account.info.email.verify-btn': 'Verify Email',
      'app.account.info.verified': '(verified)',
      'app.account.info.not-verified': '(not verified)',
      'app.account.settings.title': 'Settings',
      'app.account.notification.success.title': 'Verification Code Sent!',
      'app.account.notification.success.description':
        'We have sent you a verification code to your ',
      'app.account.verify.modal.title': 'Verify Code',
      'app.account.verify.modal.ok.text': 'Verify',
      // Account Page -> Delete Account Section
      'app.account.delete-account.delete-btn': 'Delete Account',
      'app.account.delete-account.modal.title': 'Are you sure you want to delete your account?',
      'app.account.delete-account.modal.content': 'Deleting your account will permanently erase your account data.',
      'app.account.delete-account.modal.okText': 'Delete',
      'app.account.delete-account.modal.cancelText': 'Cancel',
      // Account Page -> Change Password Info Section
      'app.account.change-password.title': 'Password',
      'app.account.change-password': 'Change password',
      // Account Page -> Change Password Modal
      'app.account.change-password.modal.title': 'Change Password',
      'app.account.change-password.modal.ok-btn': 'Change Password',
      'app.account.change-password.modal.cancel-btn': 'Cancel',
      'app.account.change-password.modal.current.placeholder': 'Current Password',
      'app.account.change-password.modal.new.placeholder': 'New Password',
      'app.account.change-password.modal.confirm.placeholder': 'Confirm Password',
      'app.account.change-password.modal.help.note': 'Note: ',
      'app.account.change-password.modal.help.note.text':
        'New Password field MUST contain at least: ',
      'app.account.change-password.modal.help.uppercase': 'An upper case letter.',
      'app.account.change-password.modal.help.lowercase': 'A lower case letter.',
      'app.account.change-password.modal.help.number': 'A number.',
      'app.account.change-password.modal.help.length': 'Minimum of 8 characters.',
      // Account Page -> Change Full Name Modal
      'app.account.change-fullname.modal.title': 'Update Personal Info',
      // Account Page -> Change Phone Number Modal
      'app.account.change-phone-number.modal.title': 'Update Phone Number',
      // Account Page -> Change Phone Number Modal
      'app.account.change-email.modal.title': 'Update Email Address',
      'app.account.change-attribute.modal.okbtn.continue': 'Continue',
      'app.account.change-attribute.modal.okbtn.done': 'Done',
      'app.account.change-attribute.modal.cancelbtn': 'Cancel',
      // Account Page -> Change Password Input validation help text
      'app.account.change-password.policies':
        "The password does not meet the requirements.",
      'app.account.change-password.new': 'Password is not valid.',
      'app.account.change-password.confirm': 'Password does not match.',
      // Account Page -> Change Full Name Information Notification
      'app.account.change-info.full-name.notification.title': 'Name Changed!',
      'app.account.change-info.full-name.notification.description':
        'Your first and last name have been changed successfully!',
      // Account Page -> Change Email Information Notification
      'app.account.change-info.email.notification.title': 'Email Changed!',
      'app.account.change-info.email.notification.description':
        'Your email address have been changed successfully! Please, enter the confirmation code to verify your new email.',
      'app.account.change-info.email.confirmation.title': 'Are you sure you want to change your email?',
      'app.account.change-info.email.confirmation.description': 'A verification code will be sent to your new email ',
      // Account Page -> Change Phone Number Information Notification
      'app.account.change-info.phone.notification.title': 'Phone Number Changed!',
      'app.account.change-info.phone.notification.description':
        'Your phone number have been changed successfully! Please, enter the confirmation code to verify your new phone number.',
      'app.account.change-info.phone.confirmation.title': 'Are you sure you want to change your phone number?',
      'app.account.change-info.phone.confirmation.description': 'A verification code will be sent to your new phone number ',
      "app.account.change-info.confirmation.ok-btn": 'Yes',
      "app.account.change-info.confirmation.cancel-btn": 'No',
      // Account Page -> Change Password Notification
      'app.account.change-password.notification.title': 'Password Changed!',
      'app.account.change-password.notification.description':
        'Your password has been changed successfully.',
      'app.account.change-password.notification.error.title':
        'Error. Limit Exceeded.',
      'app.account.change-password.notification.error.description':
        'You have reached the maximum number of attempts. Try again later.',
      // Account Page -> Verification Success Code Notifications
      'app.account.verify-code.notification.success.title.email': 'Email Verified!',
      'app.account.verify-code.notification.success.title.phone': 'Phone Verified!',
      'app.account.verify-code.notification.success.description.email':
        'Your email address has been verified successfully.',
      'app.account.verify-code.notification.success.description.phone':
        'Your phone number has been verified successfully.',
      // Account Page -> Verification Error Code Notifications
      'app.account.verify-code.notification.error.code-mismatch.title':
        'Code Mismatch!',
      'app.account.verify-code.notification.error.code-mismatch.description':
        'The code you provided does not match.',
      'app.account.verify-code.notification.error.code-expired.title':
        'Code Expired!',
      'app.account.verify-code.notification.error.code-expired.description':
        'The code you provided is already expired.',
      // Coud Recording Page
      'app.devices.cloud-recording-section.title': 'Cloud Recording Plans',
      // Error Messages
      'app.error.page-not-found-title': 'Page Not Found',
      'app.error.page-not-found':
        "The page you're looking for could not be located",
      // Amazon Web Service Errors
      'app.aws.errors.internal-server':
        'The system returns an internal error.',
      'app.aws.errors.not-authorized':
        'TODO',
      'app.aws.errors.incorrect-password':
        'The provided password is incorrect.',
      'app.aws.errors.user-not-found':
        'The username, email, or phone number provided does not exist in our system.',
      'app.aws.errors.too-many-reauests':
        'TODO',
      'app.aws.errors.limit-exceeded':
        'TODO',
      'app.aws.errors.user-not-confirmed':
        'TODO',
      'app.aws.errors.alias-in-use':
        'The phone number or email address is tied to an existing account.',
      'app.aws.errors.user-already-exist':
        'This username is already taken. Try again.',
      'app.aws.errors.invalid-auth-key':
        'The process failed.',
      'app.aws.errors.auth-key-undefined':
        'The process failed.',
        // TODO: add other strings here
    }
  };
  