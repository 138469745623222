import { Dispatch } from "react";
import { AppAction } from "../../app.action";
import { getApolloClient } from "../../apollo.client";
import { getDefaultLocationQuery, GetDefaultLocationQueryResult } from "../../../queries";
import { DeviceLiveData, Device } from "../../../models";

import { onRequestDeviceFullInfo } from "../../../utils/device/device-get-full-info.helper";
import { showNotification } from "../../../utils/extras/notification.helpers";
import { createLog } from "../../../utils/extras/log.helpers";

export const refreshDeviceList = () => {
    const apolloClient = getApolloClient();

    return async (dispatch: Dispatch<AppAction>): Promise<void> => {
        dispatch({
            type: "SetIsLoadingDevices",
            payload: true,
        } as AppAction);

        const { data, errors } = await apolloClient.query<GetDefaultLocationQueryResult>({
        // const { data, errors } = await apolloClient.query<GetDefaultLocationQueryResult>({
            query: getDefaultLocationQuery,
            fetchPolicy: 'network-only'
        });

        if (errors || !data) {
            // TODO: show error notification
            console.error(JSON.stringify(errors, null, 4));
            dispatch({
                type: "SetIsLoadingDevices",
                payload: false,
            } as AppAction);

            showNotification(
                'error',
                'Oops!',
                'Something went wrong fetching your devices'
            )
        } else {
            let tempAddedDevices: Device[] = []
            data.locations.forEach(location => {
                tempAddedDevices = tempAddedDevices.concat(location.devices)              
            })
            let tempRegisteredDevices: Device[] = data.registeredDevices;

            // Add the missing properties to the registered devices
            tempRegisteredDevices.forEach(device => {
                device.status = device.deviceType === 'videoRecorder' ? 'Local Access Only' : 'No Access';
                device.role = 'Admin';
                device.app = 'Protect';
            });

            tempAddedDevices.forEach(device => {
                device.status = 'Loading...';
                device.app = 'Protect';
            });

            dispatch({
                type: "SetDevices",
                payload: [...tempRegisteredDevices, ...tempAddedDevices],
            });

            // Do this only if the user has device(s) added
            if (tempAddedDevices.length !== 0) {
                let deviceLiveStates: DeviceLiveData = {uid: ''};
                tempAddedDevices.map(async device => {
                    // Used "getChromecastInfo" TUTK JSON command to check status and get info from devices
                    try {
                        let result = await onRequestDeviceFullInfo(device);
                        if (typeof result !== 'undefined') {
                            if (Object.prototype.hasOwnProperty.call(result, 'getChromecastInfo')) {
                                if (result.getChromecastInfo.statusCode === 200) {
                                    deviceLiveStates.status = 'Online';
                                    deviceLiveStates.channels = result.getChromecastInfo.content.channels;
                                    deviceLiveStates.model = result.getChromecastInfo.content.model;
                                    deviceLiveStates.fwVersion = result.getChromecastInfo.content.firmwareVersion;
                                    deviceLiveStates.ip = result.getChromecastInfo.content.ip;
                                    deviceLiveStates.mac = result.getChromecastInfo.content.mac;
                                } else {
                                    deviceLiveStates.status = 'Offline';
                                }
                            } else {
                                deviceLiveStates.status = 'Offline';
                            }
                        } else {
                            deviceLiveStates.status = 'Offline';
                        }
                    } catch (error) {
                        createLog(`THREW AN ERROR GETTING STATUS FOR DEVICE: ${device.name} error: ${error}`)
                        deviceLiveStates.status = 'Offline';
                    }
                    deviceLiveStates.uid = device.uid;

                    dispatch({
                        type: "SetDeviceLiveData",
                        payload: deviceLiveStates,
                    });

                    // clear the device live state
                    deviceLiveStates = {uid: '', fwVersion: '', model: '', channels: undefined, ip: '', mac: ''}
                });
            }
        }

        dispatch({
            type: "SetIsLoadingDevices",
            payload: false,
        });
    };
};
