import gql from 'graphql-tag';

export const registerDeviceMutation = gql`
    mutation registerDevice($uid: String) {
        registerDevice (data: {uid: $uid}) {
            uid
            serialNumber
            deviceType
            updatedOn
        }
    }
`;

export interface registerDeviceMutationResult {
    registerDevice: {
        uid: string,
        name?: string,
        serialNumber: string,
        deviceType: string,
        updatedOn?: Date
    }
}
