import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from '@reach/router';
import { AppState, UserState } from '../../state';
import { navigate } from '@reach/router';
import { setUser } from "../../state/user/actions/set-user.action";
import * as queryString from 'query-string'
import { refreshUserInformation } from "../utils/auth/auth-service"
import jwtDecode from 'jwt-decode'
import { User, ProfileData, CognitoUserAttributes } from '../../models';

interface ProtectedRouteProps extends RouteComponentProps {
    component: React.FC;
}

const ProtectedRoute = (props: RouteComponentProps<ProtectedRouteProps>) => {
    const { component, location } = props;
    const { user } = useSelector<AppState, UserState>(state => state.user);
    const dispatch = useDispatch()
    const parsed = queryString.parse(location.search)

    if (typeof parsed.id_token != 'undefined') {
        const userProfile: ProfileData = jwtDecode(parsed.id_token);
        let userData: User = { Username: '', AccessToken: '', RefreshToken: '', Profile: undefined};
        userData = {
            Username: parsed.username,
            AccessToken: parsed.access_token,
            RefreshToken: parsed.refresh_token,
            Profile: userProfile
        };
        // Set user data to local storage
        localStorage.setItem('user_data', JSON.stringify(userData));
        if (window && window.pathname !== '/account') {
            window.location.assign('/account')
        }
    } else if (!user.Username && location && location.pathname !== '/landing/') {
        if (typeof window !== 'undefined') {
            window.location.assign('/landing');
        }
        return null;
    } else if (localStorage.getItem('lastActive')) {
        const lastActiveTime: any = localStorage.getItem('lastActive')
        if (Date.now() - parseInt(lastActiveTime, 10) > 1000 * 60 * 15) {
            const clearUser: User = {Username: '', AccessToken: '', RefreshToken: '', Profile: undefined};
            localStorage.clear();
            dispatch(setUser(clearUser));
            navigate('/landing', {replace: true});
        }
    }

    return React.createElement(component);
};

export default ProtectedRoute;