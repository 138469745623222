import { Device } from "../../../models";

import { SetDeviceLiveDataAction } from "../actions/set-device-live-data.action";
import { DevicesState } from "../devices.state";

export const setDeviceLiveDataReducer = (
    state: DevicesState,
    action: SetDeviceLiveDataAction
): DevicesState => {
    const device = state.items.find((d) => d.uid === action.payload.uid);
    const deviceIndex = device && state.items.indexOf(device)
    // const otherDevices = state.items.filter((d) => d.uid !== action.payload.uid);
    const updatedState: DevicesState = {
        ...state,
        items: [
            ...state.items.slice(0, deviceIndex),
            {
                ...device,
                ...action.payload,
            } as Device,
            ...state.items.slice(deviceIndex! + 1)
        ],
    };

    return updatedState;

    // const device = state.items.find((d) => d.uid === action.payload.uid);
    // const otherDevices = state.items.filter((d) => d.uid !== action.payload.uid);
    // const updatedState: DevicesState = {
    //     ...state,
    //     items: [
    //         ...otherDevices,
    //         {
    //             ...device,
    //             ...action.payload,
    //         } as Device,
    //     ],
    // };

    // return updatedState;
};
