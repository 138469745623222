import thunk from "redux-thunk";
import { createLogger } from "redux-logger";
import { createStore, combineReducers, applyMiddleware, compose, CombinedState, Reducer } from "redux";

import { DevicesState, devicesReducer, DevicesAction } from "./devices";
import { UserState, userReducer, UserAction } from "./user";
import { LegacyDevicesState, legacyDevicesReducer, LegacyDeviceAction } from "./legacy-devices";

export type AppState = {
    devices: DevicesState;
    legacyDevices: LegacyDevicesState;
    user: UserState;
};

export const configureStore = (initialState?: AppState) => {
    // Redux Configuration
    const middleware = [];

    // Thunk Middleware
    middleware.push(thunk);

    // Skip redux logs in console during the tests and in production mode
    if (!process.env.NODE_ENV || !["test", "production"].includes(process.env.NODE_ENV)) {
        // Logging Middleware
        const logger = createLogger({
            level: "info",
            collapsed: true,
        })
        middleware.push(logger);
    }

    // Apply Middleware & Compose Enhancers
    const enhancer = compose(applyMiddleware(...middleware));

    // create the root reducer
    const rootReducer = combineReducers<CombinedState<AppState>, any>({
        // HACK: TS complains about some of the reducer paths returning `undefined`, investigate
        devices: devicesReducer as Reducer<DevicesState, DevicesAction>,
        legacyDevices: legacyDevicesReducer as Reducer<LegacyDevicesState, LegacyDeviceAction>,
        user: userReducer as Reducer<UserState, UserAction>,
    });

    // Create Store
    const store = createStore(rootReducer, initialState, enhancer);

    return store;
};
