import React, { useContext } from 'react';

import { IntlContext } from '../../state/intl.context';

interface FormattedMessageProps {
    id: string;
    locale?: string;
    args?: Record<string, string | React.ReactElement>;
}

export const FormattedMessage: React.FC<FormattedMessageProps> = (props) => {
    const { formatMessage } = useContext(IntlContext);
    return (
        <span>{formatMessage(props)}</span>
    );
}
