import { LegacyDevice } from "../../models";
import Axios from "axios";

export interface AddLegacyDeviceParams {
  user: string;
  uid: string;
  series: string;
  model: string;
  app: string;
}

/**
 * Add device axios instance
 */
const axiosInstance = Axios.create({
  baseURL: process.env.LEGACY_API_ENDPOINT,
  headers: {
    "x-api-key": process.env.LEGACY_API_KEY
  },
  timeout: 3000
})

/**
 * Get all legacy devices from a given user
 * @param user The user from getting the legacy devices 
 */
export const getLegacyDevices = (user: string) => {
  // params needed for the legacy endpoint
  const config = {
    params: {
      action: 'get_devices',
      email: user
    },
  }

  return axiosInstance.get('', config)
    .then(result => result.data)
    .catch(err => err);
}

/**
 * Intended to add a new device for a given user
 * @param params The params for adding a new device
 */
export const addLegacyDevice = (params: AddLegacyDeviceParams) => {
  // Params needed
  const config = {
    params: {
      action: 'add_device',
      email: params.user,
      app: params.app,
      device_series_name: params.series,
      device_component_model: params.model,
      uid: params.uid
    }
  }

  return axiosInstance.get('', config)
    .then(result => result.data)
    .catch(err => err);
}

/**
 * Intended to remove a legacy device for a given user
 * @param params The params for removing a legacy device
 */
export const removeLegacyDevice = (user: string, uid: string) => {
  const config = {
    params: {
      action: 'delete_device',
      email: user,
      uid: uid
    }
  }

  return axiosInstance.get('', config)
    .then(result => result.data)
    .catch(err => err);
}

/**
 * Creates a Legacy Device object based on an array information
 * TODO: Include the created date (needs work on backend)
 * @param deviceArrayInfo An array with information for to be gattered for create a legacy device
 */
export const createLegacyDeviceFromArrayInfo = (deviceArrayInfo: any[]) => {
  // TODO: include the name and created date values (needs backend changes)
  const aLegacyDevice: LegacyDevice = {
    serie: deviceArrayInfo[0],
    model: deviceArrayInfo[1],
    uid: deviceArrayInfo[2],
    app: deviceArrayInfo[3],
    deviceType: "legacyVideoRecorder",
    role: "Admin"
  };

  return aLegacyDevice;
}

/**
 * Inteded to get the app logo based on its name
 * @param app The app name needed for selecting the logo
 */
export const getAppLogo = (app: string) => {
  // TODO: what to show for 'unknown value'
  switch (app) {
    case "HD":
      return "/images/legacy/no-hd-logo.png";
    case "X / XHD":
      return "/images/legacy/no-xhd-logo.png";
  
    default:
      // Return Connect as default
      return "/images/legacy/no-connect-logo.png";
  }
}

/**
 * Inteded to get the app download URL based on its name
 * @param app The app name needed for returning the download URL
 */
export const getAppDownloadUrl = (app: string) => {
  // TODO: what to show for 'unknown value'
  switch (app) {
    case "HD":
      return {
        appStore: "https://apps.apple.com/us/app/night-owl-hd/id945919542",
        googlePlay: "https://play.google.com/store/apps/details?id=com.owlhd" 
      };
    case "X / XHD":
      return {
        // appStore: "https://apps.apple.com/us/app/nightowlx-hd/id1151773094",
        appStore: "https://apps.apple.com/us/app/night-owl-x/id1151675917",
        // googlePlay: "https://play.google.com/store/apps/details?id=com.wwm.nightowlx.pad" 
        googlePlay: "https://play.google.com/store/apps/details?id=com.wwm.nightowlx"
      };
  
    default:
      // Return Connect as default
      return {
        appStore: "https://apps.apple.com/us/app/night-owl-connect/id1263552250",
        googlePlay: "https://play.google.com/store/apps/details?id=com.nightowl.connect" 
      };
  }
}

/**
 * Inteded to get the video instruction URL based on the application
 * @param app The app name needed for returning the video help URL
 */
export const getAppVideoHelpTutorial = (app: string) => {
  // TODO: what to show for 'unknown value'
  switch (app) {
    case "HD":
    case "X / XHD":
      return "https://support.nightowlsp.com/hc/en-us/articles/360059473153-Night-Owl-X-App-Password-Reset-Instructions"
  
    default:
      // Return Connect as default
      return "https://support.nightowlsp.com/hc/en-us/articles/360039076774-Application-Password-Reset-Instructions"
  }
}

export const getAppDisplayName = (app: string) => {
  if (app === "X / XHD")
    return "X / X HD"
  else
    return app
}

/**
 * Inteded to get the "Learn More" URL based on the application name for the device details tooltip
 * @param app The app name needed for returning the "Learn More" URL
 */
export const getLearnMoreUrlLink = (app: string) => {
  switch (app) {
    case "HD":
      return "https://support.nightowlsp.com/hc/en-us/articles/360000354587"

    case "X / XHD":
      return "https://support.nightowlsp.com/hc/en-us/articles/115015303487"
  
    default:
      // Return Connect as default
      return "https://support.nightowlsp.com/hc/en-us/articles/360009282774"
  }
}