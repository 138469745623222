import React from "react";
import { Provider } from "react-redux";
import { AppProvider } from "./src/state/app.context";
import { configureStore } from "./src/state/app.state";

export const wrapRootElement = ({ element }) => {
    const store = configureStore();
    return (
        <Provider store={store}>
            <AppProvider>{element}</AppProvider>
        </Provider>
    )
};


// import React from "react";


// import { AppProvider } from "./src/state/app.context";

// export const wrapRootElement = ({ element }) => (
//     <AppProvider>{element}</AppProvider>
// );