exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-account-tsx": () => import("./../../../src/pages/account.tsx" /* webpackChunkName: "component---src-pages-account-tsx" */),
  "component---src-pages-cloud-tsx": () => import("./../../../src/pages/cloud.tsx" /* webpackChunkName: "component---src-pages-cloud-tsx" */),
  "component---src-pages-faqs-tsx": () => import("./../../../src/pages/faqs.tsx" /* webpackChunkName: "component---src-pages-faqs-tsx" */),
  "component---src-pages-home-tsx": () => import("./../../../src/pages/home.tsx" /* webpackChunkName: "component---src-pages-home-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-landing-tsx": () => import("./../../../src/pages/landing.tsx" /* webpackChunkName: "component---src-pages-landing-tsx" */),
  "component---src-pages-password-recovery-tsx": () => import("./../../../src/pages/password-recovery.tsx" /* webpackChunkName: "component---src-pages-password-recovery-tsx" */),
  "component---src-pages-verification-code-tsx": () => import("./../../../src/pages/verification-code.tsx" /* webpackChunkName: "component---src-pages-verification-code-tsx" */)
}

