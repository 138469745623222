import React, { useState } from 'react';

import { en, es } from '../i18n';

const locales = { en, es };

export interface IntlShape {
    locale: string;
    messages: Record<string, string>;
}

export interface IntlState {
    /**
     * Get a formatted message by id, with optional locale and interpolation arguments.
     */
    formatMessage: (data: { id: string, args?: Record<string, string | React.ReactElement>, locale?: string }) => string;

    /**
     * The currently active locale.
     */
    locale: string;

    /**
     * Set the currently active locale.
     */
    setLocale: (locale: string) => void;
}

export const IntlContext = React.createContext<IntlState>(undefined);

export const IntlProvider: React.FC = (props) => {
    const [locale, setLocale] = useState('en');

    const formatMessage = (data: { id: string, args?: Record<string, string| React.ReactElement>, locale?: string }) => {
        try {
            const { id, args, locale: localeArg } = data;
            const selectedLocale = localeArg || locale;
            const localeDict = locales[selectedLocale];
            let value = localeDict.messages[id];

            if (value === null || value === undefined) {
                `[${id}]`;
            } else if (value === '') {
                return value;
            } else {
                const argPairs = args && Object.entries(args) || [];
                if (argPairs.length) {
                    value = argPairs
                        .reduce((previous, [k, v]) => {
                            return previous.replace(`{${k}}`, v);
                        }, value);
                }
                return value;
            }
        } catch (err) {
            return `[${data.id}]`;
        }
    };

    return (
        <IntlContext.Provider value={{
            locale,
            setLocale,
            formatMessage
        }} >
            {props.children}
        </IntlContext.Provider>
    );
};

export const IntlConsumer = IntlContext.Consumer;
