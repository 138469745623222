import React from "react"
import { FormattedMessage } from "../shared/formatted-message.component"

import { Layout, Divider, Row, Col } from "antd"

export const FooterLayout: React.FC = () => (
  <Layout.Footer>
    <Divider></Divider>
    <Row role="flex">
      <Col span={24} style={{ textAlign: "left" }}>
        <FormattedMessage id="app.footer.copyright" />
      </Col>
    </Row>
  </Layout.Footer>
)
