import { Reducer } from "react";

import { UserAction } from "../../user/actions";
import { initialUserState, UserState } from "../user.state";

export const userReducer: Reducer<UserState, UserAction> = (state = initialUserState, action) => {
    let newState: UserState = state;
    switch (action.type) {
        case "SetUser":
            newState = {
                ...state,
                user: action.payload,
            } as UserState;
            break;
    }

    return newState;
};
