import { Reducer } from 'react';

import { LegacyDeviceAction } from '../actions';
import { initialLegacyDevicesState, LegacyDevicesState } from '../legacy-devices.state';

export const legacyDevicesReducer: Reducer<LegacyDevicesState, LegacyDeviceAction> = (state = initialLegacyDevicesState, action) => {
  let newState: LegacyDevicesState = state;
  switch (action.type) {
    case "AddLegacyDevice":
      newState = {
        ...state,
        legacyItems: [...state.legacyItems, action.payload]
      } as LegacyDevicesState
      break;
    case "SetIsLoadingLegacyDevices":
      newState = {
        ...state,
        legacyListLoading: action.payload
      } as LegacyDevicesState
      break;
    case "SetLegacyDevices":
      newState = {
        ...state,
        legacyItems: [...action.payload]
      } as LegacyDevicesState
      break;
  }

  return newState;
}