import { User } from '../../../models';
import { Action } from 'redux';

export interface SetUserAction extends Action {
    type: 'SetUser';
    payload: User;
}

export const setUser: (user: User) => SetUserAction = user => ({
    type: 'SetUser',
    payload: user
});
