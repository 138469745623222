import { LegacyDevice } from '../../../models';
import { Action } from 'redux';

export interface SetLegacyDevicesAction extends Action {
  type: 'SetLegacyDevices',
  payload: LegacyDevice[]
};

/**
 * Action to set all legacy devices
 * @param item An array of legacy devices
 */
export const setLegacyDevices: (devices: LegacyDevice[]) => SetLegacyDevicesAction = items => ({
  type: 'SetLegacyDevices',
  payload: items
});