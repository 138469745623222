import { Reducer } from "react";

import { DevicesAction } from "../actions";
import { initialState, DevicesState } from "../devices.state";

import { setDeviceLiveDataReducer } from "./set-device-live-data.reducer";

export const devicesReducer: Reducer<DevicesState, DevicesAction> = (state = initialState, action) => {
    let newState: DevicesState = state;
    switch (action.type) {
        case "SetDevices":
            newState = {
                ...state,
                items: [...action.payload],
            } as DevicesState;
            break;
        case "SetIsLoadingDevices":
            newState = {
                ...state,
                listLoading: action.payload,
            } as DevicesState;
            break;
        case "SetDeviceLiveData":
            newState = setDeviceLiveDataReducer(state, action);
            break;
    }

    return newState;
};
