/**
 * Intended to validated an email address
 * @param {string} email The input UTC formated date
 * @returns {boolean} true of false is email is in valid format
 */
export const isValidEmail = (email: string) => {
    const regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    
    return regex.test(email);
}
