import { Device } from "../../models";

export interface DevicesState {
    listLoading: boolean;
    items: Device[];
}

export const initialState: DevicesState = {
    listLoading: true,
    items: [],
};

