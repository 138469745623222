import { Dictionary } from "../../models";

/**
 * Intended to format the phone number nicely
 * @param {String} phoneNumber raw phone number
 * @returns {String} a reformatted phone number like +1 (xxx) xxx-xxxx
 */
export const formatPhoneNumber = (phoneNumber: string) => {
    // createLog(phoneNumber)
    const uscaRegExp = /(\d{3})(\d{3})(\d{4})/;
    const mxRegExp = /(\d{2})(\d{4})(\d{4})/;
    const cnRegExp = /(\d{3})(\d{4})(\d{4})/;
    const twRegExp = /(\d{1})(\d{4})(\d{4})/;
    const cuRegExp = /(\d{1})(\d{3})(\d{4})/;

    // Check for countries and format!
    const possibleCountryCode = phoneNumber.slice(0, 4);
    if (possibleCountryCode.includes('+1')) {
        const usNumber = phoneNumber.substring(2).match(uscaRegExp);
        return `+1 (${usNumber![1]}) ${usNumber![2]}-${usNumber![3]}`;
    }
    else if (possibleCountryCode.includes('+52')) {
        const mxNumber = phoneNumber.substring(3).match(mxRegExp);
        return `+52 (${mxNumber![1]}) ${mxNumber![2]}-${mxNumber![3]}`;
    }
    else if (possibleCountryCode.includes('+86')) {
        const cnNumber = phoneNumber.substring(3).match(cnRegExp);
        return `+86 (${cnNumber![1]}) ${cnNumber![2]}-${cnNumber![3]}`;
    }
    else if (possibleCountryCode.includes('+886')) {
        const twNumber = phoneNumber.substring(4).match(twRegExp);
        return `+886 (${twNumber![1]}) ${twNumber![2]}-${twNumber![3]}`;
    } else if (possibleCountryCode.includes('+53')) {
        const cuNumber = phoneNumber.substring(3).match(cuRegExp);
        return `+53 (${cuNumber![1]}) ${cuNumber![2]}-${cuNumber![3]}`;
    } else {
        return phoneNumber;
    }
}

/**
 * Intended to get the phone number without the country code
 * @param phoneNumber string The user's phone number
 */
export const getPhoneNumberWithoutCountryCode = (phoneNumber: string) => {
    // createLog('getPhoneNumberWithoutCountryCode: ', phoneNumber);
    // Check for countries and format!
    const possibleCountryCode = phoneNumber.slice(0, 4);
    if (possibleCountryCode.includes('+1')) {
        return phoneNumber.slice(2);
    } else if (possibleCountryCode.includes('+52')
        || possibleCountryCode.includes('+53')
        || possibleCountryCode.includes('+86')) {
        return phoneNumber.slice(3);
    } else {
        return phoneNumber.slice(4);
    }
}

/**
 * Intended to validate the phone number length based on its country code
 * @param phoneNumber string The user's phone number
 * @param countryCode string The user phone number's country code
 */
export const validatePhoneNumberLengthByCountry = (phoneNumber: string, countryCode: string) => {
    const regexForCu = /^[0-9]\d{7}$/g;
    const regexForTw = /^[0-9]\d{8}$/g;
    const regexForUsCaMx = /^[0-9]\d{9}$/g;
    const regexForCn = /^[0-9]\d{10}$/g;

    if (!phoneNumber) {
      return false;
    } else {
      switch (countryCode) {
        case '+1':
          return regexForUsCaMx.test(phoneNumber);
        case '+52':
          return regexForUsCaMx.test(phoneNumber);
        case '+53':
          return regexForCu.test(phoneNumber);
        case '+886':
          return regexForTw.test(phoneNumber);
        case '+86':
          return regexForCn.test(phoneNumber);
        default:
          return;
      }
    }
}

/**
 * Intended to get a custom validation message (checking lenght only) based on the phone country code
 * @param phoneNumber string The user's phone number
 * @param countryCode string The user phone number's country code
 */
export const getPhoneNumberValidationMessage = (phoneNumber: string, countryCode: string) => {
    // TODO: return an intl id to be used on the react component
    if (phoneNumber.length > 0) {
        switch (countryCode) {
            case '+1':
                return !validatePhoneNumberLengthByCountry(phoneNumber, countryCode)
                    ? 'The phone number MUST contain 10 digits.' : '';
            case '+52':
                return !validatePhoneNumberLengthByCountry(phoneNumber, countryCode)
                    ? 'The phone number MUST contain 10 digits.' : '';
            case '+53':
                return !validatePhoneNumberLengthByCountry(phoneNumber, countryCode)
                    ? 'The phone number MUST contain 8 digits.' : '';
            case '+86':
                return !validatePhoneNumberLengthByCountry(phoneNumber, countryCode)
                    ? 'The phone number MUST contain 11 digits.' : '';
            case '+886':
                return !validatePhoneNumberLengthByCountry(phoneNumber, countryCode)
                    ? 'The phone number MUST contain 9 digits.' : '';
            default:
                return '';
        }
    } else {
        return 'Please enter your phone number area code first.';
    }
}

/**
 * Inteded to get the maximum length for a phone number by its country
 * @param countryTextCode string The phone number country text code like 'us'
 */
export const getMaxPhoneNumberLengthByCountry = (countryTextCode: string) => {
    switch (countryTextCode) {
        case 'cn':
            return 11;
        case 'tw':
            return 9;
        case 'cu':
            return 8;
        default:
            return 10;
    }
}

/**
 * Intended to get the country state code from a given phone number
 * @param phoneNumber string The user's phone number
 */
export const getCountryFlagFromPhoneNumber = (phoneNumber: string) => {
    const canadaAreaCodes=[
        204, 226, 236, 249, 250, 289, 306, 343, 365, 367, 403, 416, 418, 431,
        437, 438, 450, 506, 514, 519, 548, 579, 581, 587, 604, 613, 639, 647,
        705, 709, 778, 780, 782, 807, 819, 825, 867, 873, 902, 905
    ]

    // Check for countries and format!
    const possibleCountryCode = phoneNumber.slice(0, 4);

    if (possibleCountryCode.includes('+1')) {
        const caStateCode = +phoneNumber.slice(2, 5);
        // createLog(typeof caStateCode, caStateCode);
        if (canadaAreaCodes.includes(caStateCode)) {
            return 'ca';
        }
        return 'us';
    }
    else if (possibleCountryCode.includes('+52')) {
        return 'mx';
    }
    else if (possibleCountryCode.includes('+86')) {
        return 'cn'
    }
    else if (possibleCountryCode.includes('+886')) {
        return 'tw'
    } else {
        return 'cu';
    }
}

/**
 * Intended to get the country code based on a country text code
 * @param country string A country text code like "us", "cu"
 */
export const getCountryNumberCodeFromCountryTextCode = (country: string) => {
    const countryCodes: Dictionary = {
        us: '+1',
        ca: '+1',
        mx: '+52',
        cn: '+86',
        tw: '+886',
        cu: '+53'
    };

    return countryCodes[country];
}