import { LegacyDevice } from '../../../models';
import { Action } from 'redux';

export interface AddLegacyDeviceAction extends Action {
  type: 'AddLegacyDevice',
  payload: LegacyDevice
};

/**
 * Action to add (register) a new legacy device
 * @param item A legacy device object
 */
export const addLegacyDevice: (device: LegacyDevice) => AddLegacyDeviceAction = item => ({
  type: 'AddLegacyDevice',
  payload: item
});