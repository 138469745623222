import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { navigate, Link } from '@reach/router';
import { setUser } from "../../state/user/actions/set-user.action";
import { AppState, UserState } from "../../state";
import { User } from "../../models";
import { FormattedMessage } from "../shared";

import { Menu, Dropdown, Avatar, Button } from "antd";
import { LogoutOutlined, DownOutlined, UserOutlined } from "@ant-design/icons";
import * as queryString from 'query-string'

export const AccountMenu: React.FC = () => {
    const dispatch = useDispatch();
    const { user } = useSelector<AppState, UserState>(state => state.user);
    const parsed = queryString.parse(location.search)

    const handleLogOut = () => {
        const clearUser: User = {Username: '', AccessToken: '', RefreshToken: '', Profile: undefined};
        localStorage.clear();
        dispatch(setUser(clearUser));
        navigate('/landing', {replace: true});
        // window.location.replace('/landing');
    }

    // User Profile Menu
    const profileMenu = (
        <Menu theme='light' className="user-menu">
            <div style={{ textAlign: 'center', marginTop: '1rem', marginBottom: '1rem' }}>
                <Avatar size="large" icon={<UserOutlined />} style={{ backgroundColor: 'var(--lightBlue)' }} />
            </div>
            <Menu.Item key="0">
                <Link to='/'>
                    <FormattedMessage id="app.auth.menu.option.devices" />
                </Link>
            </Menu.Item>
            <Menu.Item key="1">
                <Link to='/account'>
                    <FormattedMessage id="app.auth.menu.option.account" />
                </Link>
            </Menu.Item>
            <Menu.Item key="2">
                <a href='https://support.nightowlsp.com' target='_blank'>
                    <FormattedMessage id="app.auth.menu.option.support" />
                </a>
            </Menu.Item>
            <Menu.Divider />
            <Menu.Item key="3">
                <Button
                    type='link'
                    style={{ fontSize: '1rem' }}
                    onClick={handleLogOut}
                >
                    <LogoutOutlined />
                    <FormattedMessage id="app.auth.logout" />
                </Button>
            </Menu.Item>
        </Menu>
    );

    const loggedInLinks = (
        <>
            <Dropdown overlay={profileMenu} trigger={['click']}>
                <a className="ant-dropdown-link" href="#" style={{ color: 'var(--white)', fontSize: '18px' }}>
                    <FormattedMessage id="app.auth.welcome-message" />
                    {typeof parsed.username != 'undefined' ? `${parsed.username}!` : `${user!.Username}!`}
                    <DownOutlined style={{ marginLeft: '0.2rem', fontSize: '15px' }} />
                </a>
            </Dropdown>
        </>
    );

    const headerLinks = user.Username || parsed.username
        ? loggedInLinks
        : null

    return (
        <div style={{ display: 'inline', float: 'right' }}>
            {headerLinks}
        </div>
    );
};
