import { Action } from 'redux';

export interface SetIsLoadingLegacyDevicesAction extends Action {
  type: 'SetIsLoadingLegacyDevices',
  payload: boolean
};

/**
 * Action to set the loading property for the legacy devices list
 * @param isLoading The state of the loading process for the legacy devices list
 */
export const setIsLoadingLegacyDevices = (isLoading: boolean): SetIsLoadingLegacyDevicesAction => ({
  type: 'SetIsLoadingLegacyDevices',
  payload: isLoading
});