import React, { useContext, useState } from "react"
import { useDispatch } from "react-redux"
import { Link, navigate } from "@reach/router"

import { Form, Input, Button, Typography, notification } from "antd"
import { LoadingOutlined } from "@ant-design/icons"
import { CountryCodeFlag } from "../common/country-code-flag"

import { FormattedMessage } from "../../shared/formatted-message.component"
import { IntlContext } from "../../../state/intl.context"
import { setUser } from "../../../state/user/actions/set-user.action"

import {
  login,
  resendConfirmationCode,
  forgotPassword,
} from "../../../utils/auth/auth-service"
import { showNotification } from "../../../utils/extras/notification.helpers"
import { getIntlErrorID } from "../../../utils/extras/errors.helpers"
import { isValidEmail } from "../../../utils/extras/email.helpers"
import { createLog } from "../../../utils/extras/log.helpers"
import {
  getCountryNumberCodeFromCountryTextCode,
  getMaxPhoneNumberLengthByCountry,
} from "../../../utils/extras/phone.helpers"

import "../../shared/global.styles.scss"

const { Text } = Typography

interface InlineLoginFormProps {
  layout: string
}

export const InlineLoginForm: React.FC<InlineLoginFormProps> = (props) => {
  const { layout } = props
  const { formatMessage } = useContext(IntlContext)
  const dispatch = useDispatch()
  const [form] = Form.useForm()

  // LOCAL STATES
  const [loading, setLoading] = useState(false)
  const [disableForgotPasswordBtn, setDisableForgotPasswordBtn] = useState(false);
  const [phoneValidationStatus, setPhoneValidationStatus] = useState<
    "" | "error" | "success" | "warning" | "validating" | undefined
  >(undefined)
  const [userAliasStatus, setUserAliasStatus] = useState<
    "" | "error" | "success" | "warning" | "validating" | undefined
  >(undefined)
  const [passwordStatus, setPasswordStatus] = useState<
    "" | "error" | "success" | "warning" | "validating" | undefined
  >(undefined)
  const [countryTextCode, setCountryTextCode] = useState("us")
  const [phoneInputDisabled, setPhoneInputDisabled] = useState(false)
  const [userInputDisabled, setUserInputDisabled] = useState(false)
  const [deleteAccountClicked, setDeleteAccountClicked] = useState(false)

  // HANDLE FORM VAUES CHANGE
  const handleFormValuesChange = (changedValue: any, allValues: any) => {
    // createLog(allValues)
    if (allValues.loginPhoneNumber && allValues.loginPhoneNumber.length > 0) {
      setUserInputDisabled(true)
      setUserAliasStatus("success") // change user alias status if phone number is being changed
      validatePhoneNumber(allValues.loginPhoneNumber) &&
        setPhoneValidationStatus("success")
    } else if (allValues.loginPhoneNumber === "") {
      // Means the input was cleared by the user
      setUserInputDisabled(false)
    }

    if (allValues.userAlias && allValues.userAlias.length > 0) {
      setPhoneInputDisabled(true)
      setPhoneValidationStatus("success") // change phone number status if user alias is being changed
      if (allValues.userAlias.length > 2 || isValidEmail(allValues.userAlias)) {
        setUserAliasStatus("success")
      }
    } else if (allValues.userAlias === "") {
      // Means the input was cleared by the user
      setPhoneInputDisabled(false)
    }

    changedValue.loginPassword &&
      changedValue.loginPassword.length > 7 &&
      setPasswordStatus("success")
  }

  // COUNTRY FLAG ON CHANGE
  const handlePhoneCountryFlagChange = (value: string) => {
    setCountryTextCode(value)
    form.setFieldsValue({
      loginPhoneNumber: "", // Make the phone input empty if user selects different country code
    })
    setUserInputDisabled(false) // Make the user alias input enabled since the phone input was cleared
  }

  // VALIDATE PHONE NUMBER
  const validatePhoneNumber = (value: string) => {
    const regexForUsCaMx = /^[2-9]\d{9}$/g
    return regexForUsCaMx.test(value)
  }

  // HANDLE PHONE NUMBER KEY PRESS EVENT
  const handlePhoneNumberKeyPressEvent = (event: React.KeyboardEvent) => {
    const regexForPhoneNumber = /\d/g
    if (!regexForPhoneNumber.test(event.key)) {
      event.preventDefault()
    }
  }

  // HANDLE INLOGIN SUBMIT FORM
  const handleSubmitLoginFom = (values: any) => {
    if (!values.userAlias && !values.loginPhoneNumber) {
      setUserAliasStatus("error")
      setPhoneValidationStatus("error")
      showNotification(
        "error",
        "Login Required",
        "Please, enter your phone number, username, or email."
      )
    } else if (
      values.loginPhoneNumber &&
      !validatePhoneNumber(values.loginPhoneNumber)
    ) {
      setPhoneValidationStatus("error")
      showNotification(
        "error",
        "Phone Number Error",
        "Please, check your phone number."
      )
    } else if (!values.loginPassword || values.loginPassword.length < 8) {
      setPasswordStatus("error")
      showNotification(
        "error",
        "Password Error",
        "Please, check your password. It MUST have 8 or more characters."
      )
    } else {
      setLoading(true)
      const usedAttribute =
        values.userAlias ||
        `${getCountryNumberCodeFromCountryTextCode(values.countryFlag)}${
          values.loginPhoneNumber
        }`
      createLog(usedAttribute)
      login(usedAttribute, values.loginPassword)
        .then((result) => {
          // createLog(JSON.stringify(result, null, 4))
          if (typeof result === "object") {
            if (result.name === "Error") {
              showNotification(
                "error",
                "Oops!",
                "Something went wrong during sign in."
              )
              setLoading(false)
              return
            }
            setLoading(false)
            dispatch(setUser(result))
            if (deleteAccountClicked) {
              navigate("/account")
              setDeleteAccountClicked(false)
            } else {
              navigate("/", { replace: true })
            }
            // window.location.assign('/');
          } else {
            // Means an error message was received. Then, notify.
            // If the user is not confirmed, send the user a verification code
            // and redirect to the verification page.
            if (result === "User Not Confirmed") {
              setLoading(false)
              resendConfirmationCode(usedAttribute)
                .then((result) => {
                  if (typeof result === "object") {
                    // createLog(JSON.stringify(result, null, 4));
                    navigate("/verification-code", {
                      state: {
                        // TODO: ask for wording. Use intl.
                        username: usedAttribute,
                        password: values.loginPassword,
                        message: "This account has not been verified. ",
                        aliasReceiver: result.CodeDeliveryDetails.Destination,
                      },
                    })
                  } else {
                    values.loginPhoneNumber
                      ? setPhoneValidationStatus("error")
                      : setUserAliasStatus("error")
                    setPasswordStatus("error")
                    showNotification("error", "Oops!", result)
                  }
                })
                .catch((err) => {
                  // TODO: show notification if message couldn't be sent
                  createLog("Catch Error on Resend Confirmation Action")
                  // return err;
                })
            } else {
              setLoading(false)
              values.loginPhoneNumber
                ? setPhoneValidationStatus("error")
                : setUserAliasStatus("error")
              // values.loginPhoneNumber ? setUserInputDisabled(true) : setPhoneInputDisabled(true)
              setPasswordStatus("error")
              showNotification(
                "error",
                "Oops!",
                formatMessage({ id: getIntlErrorID(result) })
              )
            }
          }
        })
        .catch((err) => {
          createLog(JSON.stringify(err, null, 4))
          setLoading(false)
          showNotification(
            "error",
            "Oops!",
            "Something went wrong during sign in."
          )
        })
    }
  }

  // HANDLE FORGOT PASSWORD LINK
  const handleForgotPassword = () => {
    const usedAttribute =
      form.getFieldValue("userAlias") ||
      (form.getFieldValue("loginPhoneNumber") &&
        `${getCountryNumberCodeFromCountryTextCode(
          form.getFieldValue("countryFlag")
        )}${form.getFieldValue("loginPhoneNumber")}`)

    if (!usedAttribute) {
      setUserAliasStatus("error")
      setPhoneValidationStatus("error")
      showNotification(
        "error",
        "Forgot Password Error",
        "Please enter either your Phone number, username or email in order to reset your password. "
      )
    } else if (
      form.getFieldValue("loginPhoneNumber") &&
      !validatePhoneNumber(form.getFieldValue("loginPhoneNumber"))
    ) {
      setPhoneValidationStatus("error")
      showNotification(
        "error",
        "Phone Number Error",
        "Please, check your phone number."
      )
    } else {
      // Desabled the forgot password button
      setDisableForgotPasswordBtn(true);
      forgotPassword(usedAttribute)
        .then((result) => {
          if (typeof result === "object") {
            setLoading(false)
            navigate("/password-recovery", {
              state: {
                username: usedAttribute,
                aliasReceiver: result.CodeDeliveryDetails.Destination,
                deliveryMedium: result.CodeDeliveryDetails.DeliveryMedium,
              },
            })
            setDisableForgotPasswordBtn(false)
          } else {
            form.getFieldValue("loginPhoneNumber")
              ? setPhoneValidationStatus("error")
              : setUserAliasStatus("error")
            showNotification(
              "error",
              "Forgot Password Error",
              formatMessage({ id: getIntlErrorID(result) })
            )
            setDisableForgotPasswordBtn(false)
          }
        })
        .catch((err) => {
          // console.error("Error throwed on forgot password user entry", err)
          showNotification(
            "error",
            "Oops!",
            "Something went wrong during forgot password process."
          )

          setDisableForgotPasswordBtn(false)
        })
    }
  }

  const handleDeleteAccount = () => {
    setDeleteAccountClicked(true)

    const description = (
      <div>
        Step 1: Log In<br/><br/>
        Step 2: Remove and Reset any Devices Added to Your Account<br/><br/>
        Step 3: Cancel Cloud Plan(s) Before Deleting Your Account<br/><br/>
        Step 4: Delete Account
      </div>
    )
    notification.info({
      message: 'Steps to Delete Account',
      description: description,
      duration: null,
      style: { top: 50 },
      key: 'deleteAccountNotification',
      onClose: () => {
        setLoading(false)
      }
    })
  }

  return (
    <Form
      initialValues={{
        countryFlag: "us",
      }}
      form={form}
      layout={layout}
      className="login-form"
      onFinish={handleSubmitLoginFom}
      onValuesChange={handleFormValuesChange}
    >
      {/* PHONE NUMBER */}
      <Form.Item name="loginPhoneNumber" validateStatus={phoneValidationStatus}>
        <Input
          addonBefore={
            <CountryCodeFlag
              onChange={handlePhoneCountryFlagChange}
              inputId="loginCountryFlag"
              disabled={phoneInputDisabled}
            />
          }
          disabled={phoneInputDisabled}
          style={{ width: "100%" }}
          placeholder="Phone Number"
          onKeyPress={handlePhoneNumberKeyPressEvent}
          maxLength={getMaxPhoneNumberLengthByCountry(countryTextCode)}
          prefix={getCountryNumberCodeFromCountryTextCode(countryTextCode)}
        />
      </Form.Item>

      <div
        style={{
          marginTop: "0.4rem",
          marginRight: "0.7rem",
          marginLeft: "-0.3rem",
        }}
      >
        <Text style={{ color: "white", fontWeight: 600 }}>Or</Text>
      </div>

      {/* USER ALIAS */}
      <Form.Item name="userAlias" validateStatus={userAliasStatus}>
        <Input
          className="user-alias-input"
          placeholder={formatMessage({
            id: "app.auth.login.form.user-input.placeholder",
          })}
          disabled={userInputDisabled}
        />
      </Form.Item>

      {/* PASSWORD */}
      <div>
        <Form.Item
          name="loginPassword"
          validateStatus={passwordStatus}
          // required
        >
          <Input.Password
            className="user-password-input"
            placeholder={formatMessage({
              id: "app.auth.login.form.password-input.placeholder",
            })}
          />
        </Form.Item>

        {/* FORGOT PASSWORD LINK */}
        <div style={{ marginTop: "-0.2rem", marginLeft: "-0.1rem" }}>
          {disableForgotPasswordBtn && <LoadingOutlined style={{ color: "white", marginRight: "3px" }} />}
          <Button
            disabled={disableForgotPasswordBtn}
            type="link"
            style={{ color: "white", marginLeft: "-1rem", marginTop: "-1rem" }}
            onClick={handleForgotPassword}
          >
            Forgot password?
          </Button>
          <Button
            disabled={disableForgotPasswordBtn}
            type="link"
            style={{
              color: "#e13a3a",
              marginLeft: "-1rem",
              marginTop: "-1rem",
              fontWeight: "bold",
              textDecoration: "none",
              border: "none",
              background: "none",
              cursor: "pointer",
              transition: "color 0.3s ease",
            }}
            onClick={handleDeleteAccount}
          >
            Delete Account
          </Button>
        </div>
      </div>

      {/* LOGIN BUTTON */}
      <Form.Item>
        <Button
          loading={loading}
          className="login-btn"
          type="primary"
          htmlType="submit"
          block
          onClick={() => {
            notification.close('deleteAccountNotification')
          }}
        >
          <FormattedMessage id="app.auth.login" />
        </Button>
      </Form.Item>
    </Form>
  )
}
